<template>
  <div class="reset-pwd-bg">
    <div class="reset-pwd" @keypress.enter="onEnter">
      <div>
        <div class="line" v-if="!forget">
          <input id="original-password" type="password" :placeholder="$t('msg.originalPassword')" v-model="originalPassword"/>
        </div>
        <div class="line" v-if="forget">
          <input id="user-name" :placeholder="$t('msg.userName')" v-model="name"/>
        </div>
        <div class="line">
          <input id="captcha" maxlength="4" autocomplete="off" :placeholder="$t('msg.captcha')" v-model="captcha"/>
          <img :src="captchaSrc" @click.stop="refreshCaptcha"/>
        </div>
        <div class="line" v-if="forget">
          <input id="check-email" maxlength="8" autocomplete="off" :placeholder="$t('msg.emailCheckCode')" v-model="checkCode"/>
          <button @click="onClickCheckEmail">{{$t(checkEmailText, [checkEmailCountdown])}}</button>
        </div>
      </div>
      <div v-show="showResetPwd">
        <div class="line">
          <input id="password" type="password" :placeholder="$t('msg.password')" v-model="pwd"/>
        </div>
        <div class="line">
          <input id="confirm-password" type="password" :placeholder="$t('msg.confirmPassword')" v-model="confirmedPwd"/>
        </div>
      </div>
      <div class="line" style="justify-content: space-around; margin-top: 0.12rem;">
        <button v-show="showResetPwd" @click="onSubmit">{{$t('msg.submit')}}</button>
        <button @click="onCancel">{{$t('msg.cancel')}}</button>
      </div>
      <waiting-full v-if="requesting" />
    </div>
  </div>
</template>

<script>
import vueRouter from '@/scripts/vueRouter.js'
import serviceUser from '@/scripts/serviceUser.js'
import encrypt from '@/scripts/encrypt.js'
import WaitingFull from '@/components/WaitingFull.vue'

export default {
  name: 'ResetPwd',
  components: {
    WaitingFull
  },
  data: function() {
    return {
      captchaSrc: serviceUser.requestCaptcha(),

      checkEmailText: '',
      name: '',
      captcha: '',
      checkCode: '',
      originalPassword: '',
      pwd: '',
      confirmedPwd: '',

      checkEmailCountdown: 0,

      requesting: false
    }
  },
  props: {
    forget: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showResetPwd() {
      return !this.forget || (this.checkCode && this.checkCode.length===8)
    }
  },
  watch: {
    checkEmailCountdown: {
      handler(newCountdown) {
        if(newCountdown<=0) {
          this.checkEmailText='msg.sendEmailCheckCode'
        }
        else {
          setTimeout(() => {
            this.checkEmailCountdown=newCountdown-1
          }, 1000)
          this.checkEmailText='msg.resend'
        }
      },
      immediate: true
    }
  },
  methods: {
    refreshCaptcha() {
      this.captchaSrc=serviceUser.requestCaptcha()
    },
    onEnter() {
      if(this.showResetPwd) {
        this.onSubmit()
      }
      else if(this.forget && this.checkEmailCountdown<=0) {
        this.onClickCheckEmail()
      }
    },
    onClickCheckEmail() {
      if(this.checkEmailCountdown<=0)
        if(this.name) {
          if(this.captcha.length===4) {
            if(!this.requesting) {
              this.requesting=true
              serviceUser.sendForgetPwdEmail(this.name, this.captcha, this.$store.state.currLangKey)
                .then(response => {
                  if(response.data.status.code!==0) {
                    this.refreshCaptcha()
                  }
                  this.checkEmailCountdown=response.data.data!==null ? response.data.data : 0
                  this.requesting=false
                  alert(this.$t('msg.'+response.data.status.des))
                })
                .catch(error => {
                  this.refreshCaptcha()
                  this.requesting=false
                  alert(this.$t('msg.'+error))
                })
            }
          }
          else
            alert(this.$t('msg.invalidCaptcha'))
        }
        else
          alert(this.$t('msg.userNameEmpty'))
    },
    onSubmit() {
      if((this.forget && this.showResetPwd) || this.$store.state.loginedUserId) {
        if(encrypt.checkIntensity(this.pwd)<2)
          alert(this.$t('msg.userPwdMetric'))
        else if(this.pwd!==this.confirmedPwd)
          alert(this.$t('msg.differentPwd'))
        else if(this.captcha.length!==4)
          alert(this.$t('msg.invalidCaptcha'))
        else if(this.forget && this.checkCode.length!==8)
          alert(this.$t('msg.invalidCheckCode'))
        else {
          if(!this.requesting) {
            this.requesting=true
            let form=this.forget ? {captcha: this.captcha, name: this.name, code: this.checkCode, pwd: this.pwd} : {captcha: this.captcha, originalPwd: this.originalPassword, pwd: this.pwd}
            serviceUser.changePwd(form, this.$store.state.currLangKey)
              .then(res => {
                this.requesting=false
                alert(this.$t('msg.'+res))
                vueRouter.backOrDefault('/')
              })
              .catch(error => {
                this.refreshCaptcha()
                this.requesting=false
                alert(this.$t('msg.'+error))
              })
          }
        }
      }
    },
    onCancel() {
      vueRouter.backOrDefault('/')
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'

.reset-pwd-bg
  position: fixed
  top: 0
  left: 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100vw
  height: 100vh
  background-color: bg_color_mask
  z-index: 200

  .reset-pwd
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    box-sizing: border-box
    width: 4.8rem
    min-height: 20vh
    padding: 16px 0
    font-size: 14px
    background-color: bg_color

    .line
      width: 3.6rem
      margin: 0.08rem 0
      display: flex
      flex-direction: row
      justify-content: center
      align-items: stretch

      input
        width: 100%
        padding: 2px 4px
        border: 0
        border-radius: 4px

    button
      flex-shrink: 0
      border: none
      border-radius: 4px
      outline: none
      height: 20px
      min-width: 64px
      background-color: button_color
      white-space: nowrap
      word-break: keep-all
</style>
