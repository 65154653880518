<template>
  <div class="login-bg">
    <div class="area">
      <select-tab class="tabs" :tabTextList="[$t('msg.login'), $t('msg.register')]" v-model="forRegister" />
      <div class="info" @keypress.enter="onOk">
        <div class="line">
          <input id="user-name" :placeholder="$t('msg.userName')" v-model="form.name"/>
        </div>
        <div class="line" v-show="forRegister">
          <input id="first-name" :placeholder="$t('msg.firstName')" v-model="form.firstName"/>
        </div>
        <div class="line" v-show="forRegister">
          <input id="last-name" :placeholder="$t('msg.lastName')" v-model="form.lastName"/>
        </div>
        <div class="line">
          <input id="password" type="password" :placeholder="$t('msg.password')" v-model="form.pwd"/>
          <button id="forget-pwd" v-show="!forRegister" @click="onForgetPwd">{{$t('msg.forgetPwd')}}</button>
        </div>
        <div class="line" v-show="forRegister">
          <input id="confirm-password" type="password" :placeholder="$t('msg.confirmPassword')" v-model="form.confirmedPwd"/>
        </div>
        <div class="line" v-show="forRegister">
          <input id="email" :placeholder="$t('msg.email')" v-model="form.email"/>
        </div>
        <div class="line">
          <input id="captcha" maxlength="4" autocomplete="off" :placeholder="$t('msg.captcha')" v-model="form.captcha"/>
          <img class="captcha" :src="captchaSrc" @click.stop="refreshCaptcha"/>
        </div>
        <div class="opt">
          <button @click="onOk">{{$t('msg.ok')}}</button>
          <button @click="onCancel">{{$t('msg.cancel')}}</button>
        </div>
      </div>
      <waiting-full v-if="requesting" />
    </div>
  </div>
</template>

<script>
import vueRouter from '@/scripts/vueRouter.js'
import serviceUser from '@/scripts/serviceUser.js'
import encrypt from '@/scripts/encrypt.js'
import normStr from '@/scripts/normStr.js'
import SelectTab from '@/components/SelectTab.vue'
import WaitingFull from '@/components/WaitingFull.vue'

export default {
  name: 'Login',
  components: {
    SelectTab,
    WaitingFull
  },
  data: function() {
    return {
      forRegister: 0,
      captchaSrc: serviceUser.requestCaptcha(),
      form: {
        name: '',
        firstName: '',
        lastName: '',
        pwd: '',
        confirmedPwd: '',
        email: '',
        captcha: ''
      },

      requesting: false
    }
  },
  methods: {
    refreshCaptcha() {
      this.captchaSrc=serviceUser.requestCaptcha()
    },
    onForgetPwd() {
      this.$router.push({
        name: 'ResetPwd',
        state: {
          forget: true
        }
      })
    },
    onOk() {
      if(this.form.name) {
        if(this.form.pwd) {
          if(this.forRegister) {
            if(encrypt.checkIntensity(this.form.pwd)<2)
              alert(this.$t('msg.userPwdMetric'))
            else if(this.form.pwd!==this.form.confirmedPwd)
              alert(this.$t('msg.differentPwd'))
            else if(!normStr.validEmail(this.form.email))
              alert(this.$t('msg.invalidEmail'))
            else if(this.form.captcha.length!==4)
              alert(this.$t('msg.invalidCaptcha'))
            else {
              if(!this.requesting) {
                this.requesting=true
                serviceUser.register(this.form, this.$store.state.currLangKey)
                  .then(res => {
                    let msg=this.$t('msg.'+res)
                    serviceUser.login(this.form, this.$store.state.currLangKey)
                      .then(res => {
                        this.$store.commit('setLogined', res.data.data)
                        serviceUser.sendActivateEmail(this.$store, this.$store.state.currLangKey)
                          .then(res => {
                            msg=msg+'\n'+this.$t('msg.'+res.data.status.des)
                            alert(msg)
                            this.requesting=false
                            vueRouter.backOrDefault('/')
                          })
                          .catch(error => {
                            msg=msg+'\n'+this.$t('msg.'+res.data.status.des)
                            alert(msg)
                            console.log(this.$t('msg.'+error))
                            this.requesting=false
                          })
                      })
                      .catch(error => {
                        alert(msg)
                        console.log(this.$t('msg.'+error))
                        this.requesting=false
                      })
                  })
                  .catch(error => {
                    this.refreshCaptcha()
                    this.requesting=false
                    alert(this.$t('msg.'+error))
                  })
              }
            }
          }
          else {
            if(this.form.captcha.length!=4)
              alert(this.$t('msg.invalidCaptcha'))
            else {
              if(!this.requesting) {
                this.requesting=true
                serviceUser.login(this.form, this.$store.state.currLangKey)
                  .then(res => {
                    this.$store.commit('setLogined', res.data.data)
                    alert(this.$t('msg.'+res.data.status.des))
                    this.requesting=false
                    vueRouter.backOrDefault('/')
                  })
                  .catch(error => {
                    this.refreshCaptcha()
                    this.requesting=false
                    alert(this.$t('msg.'+error))
                  })
              }
            }
          }
        }
        else
          alert(this.$t('msg.pwdEmpty'))
      }
      else
        alert(this.$t('msg.userNameEmpty'))
    },
    onCancel() {
      vueRouter.backOrDefault('/')
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'

.login-bg
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: bg_color_mask
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  z-index: 200

  .area
    position: relative
    background-color: bg_color_dark
    width: 4.8rem
    padding: 0.8rem 0.5rem
    display: flex
    flex-direction: column
    justify-content: center
    align-items: stretch

    .tabs
      font-size: 16px

      span
        background-color: bg_color_light_dark

        &:hover
          background-color: bg_color

        &.select-tab-chosen
          background-color: bg_color

    .info
      background-color: bg_color
      padding: 0.5rem 0
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      font-size: 14px

      .line
        width: 3.6rem
        margin: 0.08rem 0
        display: flex
        flex-direction: row
        justify-content: center
        align-items: stretch

        input
          width: 100%
          padding: 2px 4px
          border: 0
          border-radius: 4px

        button
          max-width: 40%
          border: none
          border-radius: 4px
          outline: none
          background-color: button_color
          white-space: nowrap
          word-break: keep-all

        .captcha
          box-sizing: border-box
          width: 48px
          height: 20px
          object-fit: fill

      .opt
        width: 100%
        margin-top: 0.2rem
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center

        button
          border: 0
          border-radius: 4px
          min-width: 80px
          background-color: button_color
</style>