import requester from '@/scripts/requester.js'
import requesterCheckUser from '@/scripts/requesterCheckUser.js'
import encrypt from '@/scripts/encrypt.js'

function requestCaptcha() {
  return '/api/captcha?d='+new Date().getTime()
}

function requestPublicKey(name, captcha, currLangKey) {
  return requester.request('/api/key', {name: name, captcha: captcha}, currLangKey)
    .then(response => {
      return new Promise((resolve, reject) => {
        if(response.data.status.code===0)
          resolve(response.data.data)
        else
          reject(response.data.status.des)
      })
    })
}

function login(form, currLangKey) {
  var key=encrypt.scrypt(form.pwd)
  return requestPublicKey(null, form.captcha, currLangKey)
    .then(publicKey => {
      key=encrypt.encryptRSA(key, publicKey)
      return requester.request('/api/login', {name: form.name, pwd: key}, currLangKey)
    })
    .then(response => {
      return new Promise((resolve, reject) => {
        if(response.data.status.code===0)
          resolve(response)
        else
          reject(response.data.status.des)
      })
    })
}

function checkLogin() {
  return requester.sendSimplePost('/api/checklogin')
}

function register(form, currLangKey) {
  var key=encrypt.scrypt(form.pwd)
  return requestPublicKey(form.name, form.captcha, currLangKey)
    .then(publicKey => {
      key=encrypt.encryptRSA(key, publicKey)
      return requester.request('/api/register', {name: form.name, firstName: form.firstName, lastName: form.lastName, pwd: key, email: form.email, inviteCode: form.inviteCode}, currLangKey)
    })
    .then(response => {
      return new Promise((resolve, reject) => {
        if(response.data.status.code===0)
          resolve(response.data.data)
        else
          reject(response.data.status.des)
      })
    })
}

function changePwd(form, currLangKey) {
  var orginalKey=form.originalPwd ? encrypt.scrypt(form.originalPwd) : null
  var key=encrypt.scrypt(form.pwd)
  return requestPublicKey(null, form.captcha, currLangKey)
    .then(publicKey => {
      orginalKey=orginalKey ? encrypt.encryptRSA(orginalKey, publicKey) : null
      key=encrypt.encryptRSA(key, publicKey)
      return requester.request('/api/changepwd', {name: form.name, code: form.code, originalPwd: orginalKey, pwd: key}, currLangKey)
    })
    .then(response => {
      return new Promise((resolve, reject) => {
        if(response.data.status.code===0)
          resolve(response.data.data)
        else
          reject(response.data.status.des)
      })
    })
}

function sendForgetPwdEmail(name, captcha, currLangKey) {
  return requester.request('/api/forgetpwdemail', {name: name, captcha: captcha}, currLangKey)
}

function sendActivateEmail(store, currLangKey) {
  return requesterCheckUser.request(store, '/api/actemail', {}, currLangKey)
}

export default {
  requestCaptcha,
  login,
  checkLogin,
  register,
  changePwd,
  sendForgetPwdEmail,
  sendActivateEmail
}
