<template>
  <div class="content">
    <div class="content-title-area" v-if="content.ticketId">
      <div class="content-title">{{content.ticketId}}</div>
      <div :class="['content-status', 'content-status-'+(content.isOpen ? 'open' : 'closed')]">{{content.isOpen ? $t('msg.ticketStatusOpen') : $t('msg.ticketStatusClosed')}}</div>
      <div :class="['content-status', 'content-status-'+(content.passed===true ? 'passed' : 'rejected')]" v-if="content.passed!==null && content.passed!==undefined">{{content.passed ? $t('msg.ticketStatusPassed') : $t('msg.ticketStatusRejected')}}</div>
      <div class="content-title-button-area" v-if="content.isOpen && isAdmin">
        <button style="margin-right: 0.02rem" @click="onTicketReview">{{$t('msg.TicketOpt.review')}}</button>
        <button style="margin-right: 0.02rem" @click="onTicketAccept">{{$t('msg.TicketOpt.accept')}}</button>
        <button @click="onTicketReject">{{$t('msg.TicketOpt.reject')}}</button>
      </div>
    </div>
    <div class="content-info-area">
      <div class="content-owner">{{content.owner.name}}</div>
      <div class="content-time">{{content.createTime}}</div>
    </div>
    <hr v-if="showHr">
    <div class="content-ticket" v-if="content.ticketId">
      <div class="content-ticket-org-name">
        <div class="content-ticket-org-key">{{$t('msg.Ticket.orgName')}}</div>
        <div class="content-ticket-org-value">{{content.organizationName}}</div>
      </div>
      <div class="content-ticket-org-des">
        <div class="content-ticket-org-key">{{$t('msg.Ticket.orgDes')}}</div>
        <div class="content-ticket-org-value">{{content.organizationDescription}}</div>
      </div>
      <div class="content-ticket-org-type">
        <div class="content-ticket-org-key">{{$t('msg.Ticket.orgType')}}</div>
        <div class="content-ticket-org-value">{{$t('msg.Ticket.OrgType.'+content.organizationType)}}</div>
      </div>
      <div class="content-ticket-org-domain">
        <div class="content-ticket-org-key">{{$t('msg.Ticket.domain')}}</div>
        <div class="content-ticket-org-value">{{content.domain}}</div>
      </div>
      <div class="content-ticket-org-group">
        <div class="content-ticket-org-key">{{$t('msg.Ticket.groupId')}}</div>
        <div class="content-ticket-org-value">{{content.groupId}}</div>
      </div>
    </div>
    <div class="content-content" v-if="content.content">{{content.content}}</div>
    <div class="content-reply-area" v-if="content.ticketId">
      <div :class="['icon-eject', 'content-reply-area-show', showReply ? 'drop-down' : 'drop-up']" @click="onClickShowReply" />
      <div class="content-reply-block" v-show="showReply">
        <div class="content-reply">
          <ticket-content v-for="c in contentList" :key="c.id" :content="c" :isAdmin="isAdmin" :showHr="false" />
          <div class="content-reply-show-more" v-if="hasMore">
            <button @click="requestContent">{{$t('msg.showMore')}}</button>
          </div>
        </div>
        <hr>
        <div class="content-reply-new" v-if="content.isOpen">
          <textarea :placeholder="$t('msg.replyTicketPrompt')" v-model="replyText" />
          <div class="quick-reply-opt">
            <button @click="onSubmit">{{$t('msg.submit')}}</button>
          </div>
          <waiting-full v-if="requestingSubmit" />
        </div>
      </div>
      <waiting-full v-if="requestingReply" />
    </div>
    <waiting-full v-if="requesting" />
    <review-result :result="this.reviewResult" v-model="showReviewResult" />
  </div>
</template>

<script>
import serviceContent from '@/scripts/serviceContent.js'
import ReviewResult from '@/components/ReviewResult.vue'
import WaitingFull from '@/components/WaitingFull.vue'

export default {
  name: 'TicketContent',
  components: {
    ReviewResult,
    WaitingFull
  },
  data: function() {
    return {
      contentList: [],
      page: 0,
      showReply: false,
      hasMore: true,

      replyText: '',

      reviewResult: null,
      showReviewResult: false,

      requesting: false,
      requestingReply: false,
      requestingSubmit: false
    }
  },
  props: {
    content: Object,
    isAdmin: {
      type: Boolean,
      default: false
    },
    showHr: {
      type: Boolean,
      default: true
    }
  },
  emits: ['updateStatus'],
  methods: {
    onClickShowReply() {
      this.showReply=!this.showReply
      if(this.showReply && this.contentList.length===0 && this.hasMore) {
        this.requestContent()
      }
    },
    requestContent() {
      if(!this.requestingReply) {
        this.requestingReply=true
        return serviceContent.contentRead(this.$store, this.content.id, null, null, null, this.page, this.$store.state.currLangKey, this.traceId)
          .then(data => {
            if(data.length>0) {
              for(let content of data) {
                this.contentList.push(content)
              }
              this.page+=1
            }
            else {
              this.hasMore=false
            }
            this.requestingReply=false
          })
          .catch(error => {
            console.log(error)
            this.requestingReply=false
          })
      }
    },
    onSubmit() {
      if(this.replyText) {
        if(!this.requestingSubmit) {
          this.requestingSubmit=true
          let content={
            content: this.replyText
          }
          serviceContent.contentWrite(this.$store, this.content.id, content, this.$store.state.currLangKey, this.traceId)
            .then(response => {
              alert(this.$t('msg.'+response.data.status.des))
              this.requestingSubmit=false
              
              this.contentList=[]
              this.page=0
              this.hasMore=true
              this.requestContent()
            })
            .catch(error => {
              console.log(error)
              alert(this.$t('msg.TicketCode.FAILED'))
              this.requestingSubmit=false
            })
        }
      }
      else {
        alert(this.$t('msg.fieldRequiring'))
      }
    },
    onTicketReview() {
      if(this.isAdmin) {
        if(!this.requesting) {
          this.requesting=true
          serviceContent.contentReview(this.$store, this.content.id, this.$store.state.currLangKey, this.traceId)
            .then(response => {
              if(response.data.status.code===0) {
                this.reviewResult=response.data.data
                this.showReviewResult=true
              }
              else {
                alert(this.$t('msg.'+response.data.status.des))
              }
              this.requesting=false
            })
            .catch(error => {
              alert(error)
              this.requesting=false
            })
        }
      }
    },
    onTicketAccept() {
      if(this.isAdmin) {
        if(!this.requesting) {
          this.requesting=true
          serviceContent.contentClose(this.$store, this.content.id, true, this.$store.state.currLangKey, this.traceId)
            .then(response => {
              alert(this.$t('msg.'+response.data.status.des))
              if(response.data.status.code===0) {
                this.$emit('updateStatus', false)
              }
              this.requesting=false
            })
            .catch(error => {
              alert(error)
              this.requesting=false
            })
        }
      }
    },
    onTicketReject() {
      if(this.isAdmin) {
        if(!this.requesting) {
          this.requesting=true
          serviceContent.contentClose(this.$store, this.content.id, null, this.$store.state.currLangKey, this.traceId)
            .then(response => {
              alert(this.$t('msg.'+response.data.status.des))
              if(response.data.status.code===0) {
                this.$emit('updateStatus', false)
              }
              this.requesting=false
            })
            .catch(error => {
              alert(error)
              this.requesting=false
            })
        }
      }
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'

.content
  position: relative
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  box-sizing: border-box
  padding: 0.04rem
  background-color: bg_color
  font-size: 16px
  text-align: left

  .drop-down{}

  .drop-up
    transform: rotate(180deg)

  button
    border: none
    border-radius: 4px
    outline: none
    background-color: button_color
    font-size: 14px
    text-overflow: ellipsis
    white-space: nowrap
    word-break: keep-all
    overflow: hidden

  hr
    margin: 0.02rem 0.08rem
    border-width: 1px

  .content-title-area
    position: relative
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-end

    .content-title
      font-size: 20px
    
    .content-status
      margin-left: 0.04rem
      padding: 1px 4px
      font-size: 12px
      border-radius: 2px

      &.content-status-open
        background-color: #aaff88
        color: #321624

      &.content-status-closed
        background-color: #dad4d6
        color: #202a24

      &.content-status-passed
        background-color: #aaff88
        color: #321624

      &.content-status-rejected
        background-color: #ff9090
        color: #030202
    
    .content-title-button-area
      position: absolute
      top: 0
      right: 0

  .content-info-area
    position: relative
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: flex-end

    .content-owner
      font-size: 14px

    .content-time
      font-size: 12px

  .content-ticket
    padding: 0.02rem 0
    font-size: 16px

    .content-ticket-org-key
      font-weight: bold

    .content-ticket-org-value
      margin-left: 0.08rem
      font-style: italic

    .content-ticket-org-name
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-end
      margin: 0.02rem 0
      font-size: 16px

    .content-ticket-org-des
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-end
      margin: 0.02rem 0
      font-size: 16px

    .content-ticket-org-type
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-end
      margin: 0.02rem 0
      font-size: 16px

    .content-ticket-org-domain
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-end
      margin: 0.02rem 0
      font-size: 16px

    .content-ticket-org-group
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-end
      margin: 0.02rem 0
      font-size: 16px

  .content-content
    margin-top: 0.04rem
    background-color: bg_color_article
    font-size: 16px
  
  .content-reply-area
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    font-size: 16px

    .content-reply-area-show
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      box-sizing: border-box
      height: 8px
      font-size: 4px
      color: drop_mark_color

      &::before
        transform: scale(0.5)

    .content-reply-block
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: stretch
      font-size: 16px

      .content-reply
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: stretch
        font-size: 16px

        .content
          margin: 0.04rem 0
          border-left: 0.04rem solid #c0c0c0
          background-color: bg_color_dark_light

        .content-reply-show-more
          display: flex
          flex-direction: row
          justify-content: center
          align-items: flex-end
          margin: 0.04rem 0

      hr
        margin: 0.08rem 0

      .content-reply-new
        position: relative
        margin-top: 0.04rem
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: stretch
        font-size: 16px

        textarea
          resize: none
          background-color: bg_color_editor
          font-family: monospace
          white-space: pre-wrap
          word-break: break-all
          word-wrap: break-word
          min-height: 0.24rem

        .quick-reply-opt
          margin: 0.04rem 0
          display: flex
          flex-direction: row
          justify-content: flex-end
          align-items: center
</style>