function initMathJax() {
  if(window.MathJax) {
    return
  }

  window.MathJax={
    loader: {
      require: require
    },
    options: {
      enableAssistiveMml: true
    },
    tex: {
      packages: [
        'base',
  
        'action',
        'ams',
        'amscd',
        'autoload',
        'bbox',
        'boldsymbol',
        'braket',
        'bussproofs',
        'cancel',
        'cases',
        'centernot',
        'colortbl',
        'colorv2',
        'configmacros',
        'empheq',
        'enclose',
        'extpfeil',
        'gensymb',
        'html',
        'mathtools',
        'mhchem',
        'newcommand',
        'noerrors',
        'noundefined',
        'physics',
        'require',
        'setoptions',
        'tagformat',
        'textcomp',
        'textmacros',
        'unicode',
        'upgreek',
        'verb',
      ],
      inlineMath: [['\\$', '\\$'], ['\\(', '\\)']],
      displayMath: [['$$', '$$'], ['\\[', '\\]']],
      processEscapes: false
    },
    chtml: {
      fontURL: 'fonts/mathjax/chtml/tex-woff-v2',
    },
    // svg: {
    //   fontCache: 'global'
    // },
    startup: {
      elements: null,
      typeset: false
    }
  }
  
  require('mathjax-full/components/src/startup/lib/startup.js');
  require('mathjax-full/components/src/core/core.js');
  require('mathjax-full/components/src/input/tex-base/tex-base.js');
  require('mathjax-full/components/src/input/tex/extensions/all-packages/all-packages.js');
  require('mathjax-full/components/src/input/mml/mml.js');
  require('mathjax-full/components/src/input/asciimath/asciimath.js');
  require('mathjax-full/components/src/output/chtml/chtml.js');
  require('mathjax-full/components/src/output/chtml/fonts/tex/tex.js');
  // require('mathjax-full/components/src/output/svg/svg.js');
  // require('mathjax-full/components/src/output/svg/fonts/tex/tex.js');
  require('mathjax-full/components/src/ui/safe/safe.js');
  // require('mathjax-full/components/src/ui/menu/menu.js');
  // require('mathjax-full/components/src/ui/lazy/lazy.js');
  require('mathjax-full/components/src/a11y/assistive-mml/assistive-mml.js');
  require('mathjax-full/components/src/startup/startup.js');
  
  window.MathJax.loader.preLoad(
    'core',
    'input/tex-base',
    '[tex]/all-packages',
    'input/mml',
    'input/asciimath',
    'output/chtml',
    'output/chtml/fonts/tex',
    // 'output/svg',
    // 'output/svg/fonts/tex',
    'ui/safe',
    // 'ui/menu',
    // 'ui/lazy',
    'a11y/assistive-mml'
  );
  
  window.MathJax.config.startup.ready()
}

new Promise(function(resolve, reject) {
  try {
    initMathJax()
    resolve()
  }
  catch (error) {
    reject(error)
  }
})
  .then(() => {
    window.mathJaxReady=true
  })
  .catch(() => {
    window.MathJax=null
  })

function typeset(doms) {
  if(window.mathJaxReady) {
    window.MathJax.typeset(doms)
  }
}

function convert(doms) {
  if(window.mathJaxReady) {
    window.MathJax.texReset(doms)
    window.MathJax.typesetClear(doms)
    return window.MathJax.typeset(doms)
  }
}

export default {
  typeset,
  convert
}
