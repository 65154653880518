import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import User from '../views/User.vue'
import Activate from '../views/Activate.vue'
import ResetPwd from '../views/ResetPwd.vue'
import Ticket from '../views/Ticket.vue'
import Component from '../views/Component.vue'
import SearchView from '../views/SearchView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/activate',
    name: 'Activate',
    component: Activate,
    props: route => ({
      type: route.query.type,
      userId: route.query.id,
      code: route.query.code
    })
  },
  {
    path: '/resetpwd',
    name: 'ResetPwd',
    component: ResetPwd,
    props: route => ({
      forget: history.state.forget
    })
  },
  {
    path: '/ticket/:userId',
    name: 'Ticket',
    component: Ticket,
    props: route => ({
      userId: route.params.userId,
      isAdmin: route.query.admin && route.query.admin.toLowerCase()==='true',
      showAll: false
    })
  },
  {
    path: '/ticket/review',
    name: 'TicketReview',
    component: Ticket,
    props: route => ({
      isAdmin: route.query.admin && route.query.admin.toLowerCase()==='true',
      showAll: route.query.a && route.query.a.toLowerCase()==='true',
    })
  },
  {
    path: '/component/:groupId/:artifactId',
    name: 'ComponentArtifact',
    component: Component
  },
  {
    path: '/component/:groupId/:artifactId/:version',
    name: 'ComponentVersion',
    component: Component
  },
  {
    path: '/search',
    name: 'SearchView',
    component: SearchView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
