<template>
  <div id="nav-bar">
    <div class="nav-bar-area">
      <div class="zone">
        <div class="sub">
          <div class="title" @click="onClickHome">Concopilot</div>
        </div>
        <div class="sub">
          <span class="icon-search icon-area-search" @click="onEnter" />
          <input id="search-input" :placeholder="$t('msg.searchPlaceholder')" ref="searchInput" v-model="searchContent" @input="onInput" @keyup.enter="onEnter" />
          <span class="icon-cross icon-area-clear" @click="searchContent=''" />
          <div id="search-list" ref="searchList">
            <component-summary v-for="item in searchResults" :key="item.id" :item="item" @click="$refs.searchInput.blur()" />
            <button v-show="searchResults.length>0 && continuationToken" @click="onClickSearchMore">{{$t('msg.showMore')}}</button>
          </div>
        </div>
        <div class="sub">
          <div class="icon-menu icon-area" />
          <div id="menu-list">
            <a class="icon-user menu-item" v-show="this.$store.state.logined" @click="onClickUser">{{$t('msg.user')}}</a>
            <a class="menu-item" v-show="!this.$store.state.logined" @click="onClickLogin">{{$t('msg.loginOrRegister')}}</a>
            <a class="menu-item" v-show="this.$store.state.logined" @click="onClickLogout">{{$t('msg.logout')}}</a>
            <a id="lang-button" class="icon-earth menu-item">{{this.$store.state.currLang}}</a>
            <div id="lang-list">
              <a class="menu-item" v-for="lang in langNames" :key="lang" @click="changLang(lang)">{{lang}}</a>
            </div>
            <a id="sponsor" class="icon-heart menu-item" href="https://github.com/sponsors/ConCopilot">{{$t('msg.sponsor')}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueRouter from '@/scripts/vueRouter.js'
import requester from '@/scripts/requester.js'
import langs from '../vuei18n/langs.js'
import serviceComponent from '@/scripts/serviceComponent.js'
import ComponentSummary from './ComponentSummary.vue'

const axios=require('axios')
axios.defaults.withCredentials=true

export default {
  name: 'Navigation',
  components: {
    ComponentSummary
  },
  data: function() {
    return {
      langNames: langs.langMap.names,
      searchContent: null,

      timer: null,
      cancelSource: null,

      searchResults: [],
      continuationToken: null
    }
  },
  props: {
    imgRenderMaxCount: {
      type: Number,
      default: 4
    },
    imgRenderMaxLimitCount: {
      type: Number,
      default: 4
    }
  },
  methods: {
    onClickHome() {
      this.$router.push('/')
    },
    onClickBackToStart() {
      vueRouter.backToStart()
    },
    onClickBack() {
      vueRouter.backOrDefault('/')
    },
    onInput() {
      this.trySendSearch(this.searchContent)
    },
    onEnter() {
      this.$router.push({
        path: '/search',
        query: {
          q: this.searchContent
        }
      })
    },
    onClickUser() {
      this.$router.push({
        name: 'User',
        query: {
          id: this.$store.state.loginedUserId
        }
      })
    },
    onClickLogin() {
      this.$router.push('/login')
    },
    onClickLogout() {
      requester.request('/api/logout', {}, this.$store.state.currLangKey)
        .then(response => {
          if(response.data.status.code===0) {
            alert(this.$t('msg.'+response.data.data))
            this.$store.commit('setLogined', null)
          }
          else 
            this.$store.commit('setLogined', null)
        })
    },
    onClickSearchMore() {
      this.$router.push({
        name: 'SearchView',
        query: {
          q: this.searchContent
        },
        state: {
          rd: this.$cache.put({
            searchResults: this.searchResults,
            continuationToken: this.continuationToken,
            refresh: true,

            // traceId: this.traceId
          })
        }
      })
    },
    changLang(lang) {
      let langKey=langs.langMap.nameToKey[lang]
      this.$store.commit('setCurrLang', lang, langKey)
      this.$i18n.locale=langKey
    },
    trySendSearch(query) {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      if(query) {
        this.timer=setTimeout(() => {
          return this.requestSearch(query)
            .then(data => {
              this.searchResults=data.components
              this.continuationToken=data.continuationToken
            })
            .catch(error => {
              console.log(error)
            })
        }, 1000)
      }
    },
    requestSearch(query) {
      this.cancelSource && this.cancelSource.cancel()
      this.cancelSource=axios.CancelToken.source()
      return serviceComponent.requestSearch(this.$store, query, null, null, this.continuationToken, this.$store.state.currLangKey, this.cancelSource.token)
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'
@import '../styles/constant.styl'

#nav-bar
  height: nav_bar_height
  width: 7.5rem
  position: relative
  z-index: 100

  .nav-bar-area
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    background-color: navigation_bg_color
    box-sizing: border-box
    height: nav_bar_height
    // width: 100%
    font-size: 16px
    position: fixed
    top: 0
    // left: 0

    .zone
      position: relative
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      box-sizing: border-box
      width: 7.5rem

      .sub
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center

        .title
          box-sizing: border-box
          padding: 0 5px
          font-family: Tahoma, sans-serif
          font-size: 18px
          font-weight: bold
          font-style: italic
          color: navigation_text_color
          cursor: pointer

        .icon-area
          box-sizing: border-box
          width: 24px
          height: 24px
          padding: 5px
          font-size: 14px
          color: navigation_text_color
          cursor: pointer

          &:hover
            background-color: item_bg_color_hover
            color: navigation_text_color_hover

          &:hover+#menu-list
            display: flex

        .icon-area-search
          position: relative
          left: 16px
          font-size: 10px
          color: navigation_text_color_search
          padding: 2px
          cursor: pointer

        .icon-area-clear
          position: relative
          right: 16px
          font-size: 10px
          color: navigation_text_color_clear
          padding: 2px
          cursor: pointer

        #search-input
          box-sizing: border-box
          width: calc(7.5rem - 176px)
          max-width: 4.4rem
          padding: 3px 16px
          border: 0px
          border-radius: 4px
          font-size: 16px
          background-color: bg_color_editor

          &:hover~#search-list,
          &:focus~#search-list
            display: block

        #search-list
          display: none
          position: absolute
          top: 100%
          left: 0.2rem
          width: 7.1rem
          box-sizing: border-box
          padding: 0 0.16rem
          border-style: none
          background-color: navigation_bg_color
          max-height: 'calc(85vh - %s)' % @height
          overflow: auto

          &:hover
            display: block

          button
            margin-bottom: 0.04rem
            min-width: 60px

        #menu-list
          display: none
          position: absolute
          top: 100%
          right: 0
          padding: 1px 0
          flex-direction: column
          justify-content: center
          align-items: stretch
          background-color: navigation_bg_color
          font-size: 14px

          &:hover
            display: flex

          .menu-item
            box-sizing: border-box
            height: 20px
            min-width: 60px
            margin: 1px 2px
            padding: 4px
            background-color: item_bg_color
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            cursor: pointer

            &::before
              font-size: 12px

            &:hover
              background-color: item_bg_color_hover

            &:hover+#lang-list
              display: flex

          #lang-button

            &:before
              margin-right: 0.02rem
              color: #0080e2

          #lang-list
            display: none
            position: absolute
            top: calc(100% - 46px)
            right: calc(100% - 2px)
            padding: 1px 0
            flex-direction: column
            justify-content: center
            align-items: stretch
            background-color: navigation_bg_color

            &:hover
              display: flex

          #sponsor
            text-decoration: none
            color: default

            &:before
              margin-right: 0.02rem
              color: #ff00ec
</style>
