import router from '../router'

var startPos=-1

router.beforeEach((to, from, next) => {
  if (startPos<0)
    startPos=window.history.state.position
  next()
})

function back() {
  if(window.history.state.position>startPos)
    router.go(-1)
}

function backToStart() {
  if(window.history.state.position>startPos)
    router.go(-window.history.state.position+startPos)
}

function backOrDefault(route) {
  if(window.history.state.position>startPos)
    router.go(-1)
  else
    router.replace(route)
}

export default {
  back,
  backToStart,
  backOrDefault
}
