var localeName={
  'zh': '中文',
  'en': 'English'
}
var langMap={
  keyToName: {},
  nameToKey: {},
  names: []
}

for(var k in localeName) {
  var langName=localeName[k]
  langMap.keyToName[k]=langName
  langMap.nameToKey[langName]=k
  langMap.names.push(langName)
}

function getDefaultLang() {
  var lang=(localStorage.getItem('currLangKey') || navigator.language || navigator.browserLanguage || navigator.userLanguage || 'zh').substr(0, 2)
  if(!localeName[lang])
    lang='zh'
  return lang
}

export default {
  localeName,
  langMap,
  getDefaultLang
}