function zeroize(value, length=2) {
  value=String(value)
  for(var i=0, zeros=''; i<(length-value.length); i++) {
    zeros+='0'
  }
  return zeros+value
}

function toLocalDateStr(utcStr) {
  let date=new Date(Date.UTC(utcStr.substring(0, 4), utcStr.substring(5, 7)-1, utcStr.substring(8, 10), utcStr.substring(11, 13), utcStr.substring(14, 16), utcStr.substring(17, 19), utcStr.substring(20, 23)))
  return date.getFullYear()+'-'+zeroize(date.getMonth()+1)+'-'+zeroize(date.getDate())+' '+zeroize(date.getHours())+':'+zeroize(date.getMinutes())
}

let formatRegExp=new RegExp('"[^"]*"|\'[^\']*\'|\\b(?:d{1,4}|m{1,4}|yy(?:yy)?|([hHMstT])\\1?|SSS|[lLZ])\\b', 'g')
function format(date, fmt) {
  return fmt.replace(formatRegExp, function($0) {
    switch($0) {
    case 'd':
      return date.getDate()
    case 'dd':
      return zeroize(date.getDate())
    case 'ddd':
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'][date.getDay()]
    case 'dddd':
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()]
    case 'M':
      return date.getMonth()+1
    case 'MM':
      return zeroize(date.getMonth()+1)
    case 'MMM':
      return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()]
    case 'MMMM':
      return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][date.getMonth()]
    case 'yy':
      return String(date.getFullYear()).substring(2)
    case 'yyyy':
      return date.getFullYear()
    case 'h':
      return date.getHours()%12 || 12
    case 'hh':
      return zeroize(date.getHours()%12 || 12)
    case 'H':
      return date.getHours()
    case 'HH':
      return zeroize(date.getHours())
    case 'm':
      return date.getMinutes()
    case 'mm':
      return zeroize(date.getMinutes())
    case 's':
      return date.getSeconds()
    case 'ss':
      return zeroize(date.getSeconds())
    case 'SSS':
      return zeroize(date.getMilliseconds(), 3)
    case 'l':
      return zeroize(date.getMilliseconds(), 3)
    case 'L':
      var m = date.getMilliseconds()
      if(m>99) {
        m=Math.round(m/10)
      }
      return zeroize(m)
    case 'tt':
      return date.getHours()<12 ? 'am' : 'pm'
    case 'TT':
      return date.getHours()<12 ? 'AM' : 'PM'
    case 'Z':
      return date.toUTCString().match(/[A-Z]+$/)
    default:
      return $0.substr(1, $0.length-2)
    }
  })
}

export default {
  toLocalDateStr,
  format
}
