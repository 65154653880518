import { createI18n } from 'vue-i18n'
import langs from './langs.js'

const i18n=createI18n({
  locale: langs.getDefaultLang(),

  messages: {
    'zh': require('../assets/lang/zh.js'),
    'en': require('../assets/lang/en.js')
  }
})

export default i18n
