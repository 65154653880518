function validMobile(mobile) {
  return mobile && /^\+?[\d]+(-?[\d]+)+$/.test(mobile)
}

function validEmail(email) {
  return email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export default {
  validMobile,
  validEmail
}
