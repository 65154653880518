import requesterCheckUser from '@/scripts/requesterCheckUser.js'
import dates from '@/scripts/dates.js'

const yaml=require('js-yaml')

function normComponent(item) {
  if(item.createTime) {
    item.createTime=dates.toLocalDateStr(item.createTime)
  }
}

function requestSearch(store, query, groupId, artifactId, continuationToken, currLangKey, cancelToken) {
  let param={
    q: query,
    g: groupId,
    a: artifactId,
    token: continuationToken
  }
  let moreParams={}
  if(cancelToken) {
    moreParams.cancelToken=cancelToken
  }
  return requesterCheckUser.request(store, '/api/repo/search', param, currLangKey, null, moreParams)
    .then(response => {
      return new Promise((resolve, reject) => {
        if(response.data.status.code===0) {
          let data=response.data.data
          for(let item of data.components) {
            normComponent(item)
          }
          resolve(data)
        }
        else
          reject(response.data.status.des)
      })
    })
}

let ignore_suffixes=new Set(['.md5', '.sha1', '.sha256', '.sha512', '.asc'])

function requestComponent(store, groupId, artifactId, version) {
  groupId=groupId.split('.').join('/')
  return requesterCheckUser.sendSimplePost(store, `/repository/releases/${groupId}/${artifactId}/${version}`)
    .then(data => {
      return new Promise((resolve, reject) => {
        if(data.status===200) {
          data=yaml.load(data.data)
          let file_prefix=`/${artifactId}-${version}-`
          let re=[]
          for(let uri of data) {
            let suffix=uri.substring(uri.lastIndexOf('.'))
            if(!ignore_suffixes.has(suffix)) {
              re.push({
                name: uri.substring(uri.lastIndexOf(file_prefix)+file_prefix.length),
                uri: uri
              })
            }
          }
          resolve(re)
        }
        else {
          reject(data)
        }
      })
    })
}

function requestAsset(store, url) {
  return requesterCheckUser.sendFetch(store, `/repository/releases/${url}`)
    .then(response => {
      return new Promise((resolve, reject) => {
        if(response.status===200) {
          resolve(response)
        }
        else {
          reject(response)
        }
      })
    })
}

export default {
  requestSearch,
  requestComponent,
  requestAsset
}
