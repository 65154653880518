import requesterCheckUser from '@/scripts/requesterCheckUser.js'
import dates from '@/scripts/dates.js'

function normContent(item) {
  if(item.createTime) {
    item.createTime=dates.toLocalDateStr(item.createTime)
  }
  if(item.modifyTime) {
    item.modifyTime=dates.toLocalDateStr(item.modifyTime)
  }
}

function contentWrite(store, pId, content, currLangKey, traceId=null) {
  return requesterCheckUser.request(store, '/api/ticket/write', {pId: pId, c: content}, currLangKey, traceId)
}

function contentRead(store, pId, uId, all, openOnly, page, currLangKey, traceId=null) {
  let params={
    pId: pId,
    uId: uId,
    p: page
  }
  if(all) {
    params.a=true
  }
  if(openOnly) {
    params.o=true
  }
  return requesterCheckUser.request(store, '/api/ticket/read', params, currLangKey, traceId)
    .then(response => {
      return new Promise((resolve, reject) => {
        if(response.data.status.code===0) {
          let data=response.data.data
          for(let item of data) {
            normContent(item)
          }
          resolve(data)
        }
        else
          reject(response.data.status.des)
      })
    })
}

function contentReview(store, id, currLangKey, traceId=null) {
  return requesterCheckUser.request(store, '/api/ticket/review', {id}, currLangKey, traceId)
}

function contentClose(store, id, accept, currLangKey, traceId=null) {
  return requesterCheckUser.request(store, '/api/ticket/close', {id: id, ag: accept}, currLangKey, traceId)
}

export default {
  contentWrite,
  contentRead,
  contentReview,
  contentClose
}
