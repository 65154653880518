var ScryptJS=require('scrypt-js')
var JSEncrypt=require('jsencrypt')
var buffer=require('buffer')

function scrypt(str) {
  const N=16384, r=8, p=1
  const dkLen=256
  return buffer.Buffer(ScryptJS.syncScrypt(buffer.Buffer(str, 'utf8'), [], N, r, p, dkLen)).toString('base64')
}

function encryptRSA(str, publicKey) {
  var encryptor=new JSEncrypt.JSEncrypt()
  encryptor.setPublicKey(publicKey)
  return encryptor.encrypt(str)
}

function checkIntensity(pwd){ 
  var m=0
  var modes=0
  for(var i=0; i<pwd.length; i++) {
    var charType=0
    var t=pwd.charCodeAt(i)
    if(t>=48 && t <58)
      charType=1
    else if(t>=65 && t <91)
      charType=2
    else if(t>=97 && t <123)
      charType=4
    else
      charType=4
    modes|=charType;
  } 
  for(i=0; i<4; i++) {
    if(modes & 1)
      m++
    modes>>>=1
  } 
  if(pwd.length<6)
    m=1
  if(pwd.length<=0)
    m=0
  return m
}

export default {
  scrypt,
  encryptRSA,
  checkIntensity
}
