<template>
  <div class="select-tabs">
    <span :class="{'select-tab-chosen': idx===modelValue}" v-for="(text, idx) in tabTextList" :key="text" @click="onClick(idx)">{{text}}</span>
  </div>
</template>

<script>
export default {
  name: 'SelectTab',
  data: function() {
    return {
    }
  },
  props: {
    tabTextList: Array,

    modelValue: {
      type: Number,
      default: -1
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onClick(idx) {
      this.$emit('update:modelValue', idx)
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'

.select-tabs
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-end
  width: 100%

  span
    box-sizing: border-box
    border-top-left-radius: 5px
    border-top-right-radius: 20px
    padding: 1px 16px 1px 4px
    text-align: left
    text-overflow: ellipsis
    white-space: nowrap
    word-break: keep-all
    overflow: hidden
    min-width: 80px
    background-color: tab_bg_color

    &:hover
      background-color: tab_bg_color_hover

    &.select-tab-chosen
      background-color: tab_bg_color_hover
</style>