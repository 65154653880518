export const msg={
  ok: 'ok',
  submit: 'Submit',
  cancel: 'Cancel',
  save: 'Save',
  clickToShowMore: 'Click to Show More',

  searchPlaceholder: 'Type here to search',

  user: 'Profile',
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  loginOrRegister: 'Login/Register',
  userName: 'User Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
  originalPassword: 'Original Password',
  changePwd: 'Change Password',
  forgetPwd: 'Forget',
  confirmPassword: 'Confirm Password',
  email: 'E-mail',
  captcha: 'Captcha',
  emailCheckCode: 'Email Check Code',
  sendEmailCheckCode: 'Send',

  unautherizedAccess: 'Unautherized Access',
  authTimeout: 'Auth timeout!',

  userNameEmpty: 'User name should not be empty!',
  pwdEmpty: 'Password should not be empty!',
  differentPwd: 'Different password',
  invalidEmail: 'Illegal email address',
  invalidCaptcha: 'Captcha code should contain only 4 characters',
  userNameMetric: 'User name should contain 4-24 characters, and must not contain sensitive words, control characters, white space or symbol, and should not start with numbers.',
  userPwdMetric: 'Password should contain at least 6 characters, and include at least two types in Upper Case Letter, Lower Case Letter, Numbers, and Special Characters.',
  invalidCheckCode: 'Email check code should contain only 8 characters',

  sendActivateEmail: 'Send Activate Email',
  resend: 'Resend ({0})',
  activateWaiting: 'Activating...',
  activateSuccess: 'User Account Activate Successful',
  activateFail: 'User Account Activate Failed',

  fieldRequiring: 'All required fields must be filled',

  latestRelease: 'Last version',
  publishedOn: 'released on',

  version: 'Version',
  time: 'Time',
  componentAssets: 'Component Assets',

  sponsor: 'Sponsor',

  CaptchaStatus: {
    SUCCESS: 'Captcha Successful',
    FAILED: 'Incorrect Captcha Code',
    TOO_FAST: 'Captcha too fast',
    EXPIRED: 'Captcha Expired'
  },

  CheckCodeStatus: {
    SUCCESS: 'Check Successful',
    FAILED: 'Incorrect Check Code',
    TOO_FAST: 'Check too fast',
    EXPIRED: 'Check Code Expired'
  },

  RegisterCode: {
    SUCCESS: 'Register Successful',
    ILLEGAL_NAME: 'Illegal User Name!',
    ILLEGAL_EMAIL: 'Illegal Email Address',
    DUPLICATED_NAME: 'User Name has been used',
    FAILED: 'Register failed'
  },

  LoginCode: {
    SUCCESS: 'Login Successful',
    FAILED: 'Login failed. Incorrect User Name or Password',
    DELETED_USER: 'Login failed. User has been permanently deleted'
  },

  LogoutCode: {
    SUCCESS: 'Logout Successful',
    FAILED: 'Logout failed',
  },

  UserForgetPwdCode: {
    SUCCESS: 'A length 8 check code has sent to your email address (from no-reply@concopilot.org)',
    ILLEGAL_ACCESS: 'Illegal Access',
    NO_SUCH_USER: 'User is not existed',
    EMAIL_COOL_DOWN: 'Please retry later',
    EMAIL_SENDING_FAILED: 'Password reset check code email sending failed'
  },

  ChangePwdCode: {
    SUCCESS: 'Your password has successfully changed',
    ILLEGAL_ACCESS: 'Illegal Access',
    INCORRECT_ORIGINAL_PWD: 'Incorrect Orignal Password',
    NO_SUCH_USER: 'User is not existed'
  },

  Role: {
    ADMIN: 'Admin',
    REGISTERED: 'Registered',
    ANONYMOUS: 'Anonymous'
  },

  UserStatus: {
    NORMAL: 'Normal',
    NONACTIVATED: 'Nonactivated',
    DELETED: 'Deleted'
  },

  UserProfile: {
    firstName: 'First Name：',
    lastName: 'Last Name：',
    email: 'E-mail：',
    createTime: 'Registration Time：',
    lastLoginTime: 'Last Login Time：'
  },

  UserEditCode: {
    SUCCESS: 'Save Successful',
    NOT_LOGGED_IN: 'Please login first',
    ILLEGAL_USER_STATUS: 'Your current Status is not allowed to modify user Profile',
    ILLEGAL_ACCESS: 'Illegal Access',
    ILLEGAL_NAME: 'Illegal User Name',
    ILLEGAL_EMAIL: 'Illegal Email Address',
    NOT_ALLOWED_EXPLICIT: 'You are not allow to modify the "Show Explicit Contents"'
  },

  UserActivateCode: {
    SUCCESS_EMAIL: 'An activate email has been sent to your mailbox (from no-reply@concopilot.org), please check it to activate your account',
    NOT_LOGGED_IN: 'Please login first',
    ALREADY_ACTIVATED: 'Your account is already activated',
    ILLEGAL_USER_STATUS: 'Your are not Nonactivated user',
    EMAIL_COOL_DOWN: 'Please retry later',
    EMAIL_SENDING_FAILED: 'Activate email sending failed'
  },

  createOrganization: 'Create Organization',
  allOrganizationCreations: 'all submissions >>',
  showMore: 'show more',
  showOpenOnly: 'show open ticket only',
  reviewTickets: 'Rreview Tickets',
  ticketStatusOpen: 'open',
  ticketStatusClosed: 'closed',
  ticketStatusPassed: 'passed',
  ticketStatusRejected: 'rejected',
  replyTicketPrompt: 'Reply to this ticket',
  unavailableContent: '** This content cannot be displayed **',

  TicketOpt: {
    review: 'Review',
    accept: 'Accept',
    reject: 'Reject'
  },

  Ticket: {
    OrgType: {
      COMPANY: 'Company',
      COMMUNITY: 'Community'
    },

    orgName: 'Organization Name',
    orgDes: 'Organization Description',
    orgType: 'Organization Type',
    domain: 'Domain',
    groupId: 'GroupId'
  },

  TicketCode: {
    SUCCESS: 'Request Successful',
    UNAUTHORIZED_READ: 'You are not authorized to read the ticket',
    UNAUTHORIZED_WRITE: 'You are not authorized to submit a ticket',
    UNAUTHORIZED_GROUP_ID_QUERY: 'You are not authorized to query GroupId',
    UNAUTHORIZED_REVIEW: 'You are not authorized to review the ticket',
    UNAUTHORIZED_CLOSE: 'You are not authorized to close the ticket',
    ILLEGAL_ORG_NAME: 'Illegal organization name',
    ILLEGAL_ORG_DES: 'Illegal organization description',
    ILLEGAL_DOMAIN: 'Illegal domain',
    ILLEGAL_DOMAIN_FOR_GROUP_ID: 'Your input Domain for GroupId is illegal',
    FAILED_TO_INSERT_DB: 'Request Failed',
    FAILED: 'Request Failed'
  },

  ReviewResult: {
    owned: 'Ownership Confirmation',
    ticketId: 'Ticket Id',
    checkedUrl: 'Checked URL',
    domain: 'Applied Domain',
    groupId: 'Applied GroupId'
  },

  RepositoryCode: {
    SUCCESS: 'Operation Successful',
    UNAUTHORIZED_GRANT: 'You are not authorized to do this operation',
    UNAUTHORIZED_ACCESS: 'You are not authorized to access the content',
    UNAUTHORIZED_UPLOAD: 'You are not authorized to upload assets',
    UPLOAD_WITH_EMPTY_PATH: 'Uploading path should not be empty',
    UPLOAD_WITH_ILLEGAL_ARTIFACT_ID: 'Illegal ArtifactId in uploading',
    UPLOAD_WITH_ILLEGAL_VERSION: 'Illegal Version in uploading',
    UPLOAD_WITH_ILLEGAL_CONFIG_FILE: 'Illegal config file in uploading',
    FAILED: 'Operation Failed'
  }
}
