<template>
  <div id="home" class="markdown-body hljs-body-dark" ref="home" />
</template>

<script>
import requester from '@/scripts/requester.js'
import markIt from '@/scripts/markIt.js'

export default {
  name: 'HomeView',
  created() {
    this.$watch(
      () => this.$store.state.currLangKey,
      (newLang) => {
        this.updateContent(newLang)
      },
      {immediate: true}
    )
  },
  methods: {
    updateContent(newLang) {
      requester.sendSimpleGet(`/assets/home/${newLang}.md`)
        .then(response => {
          markIt.markToDom(response.data, this.$refs.home)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'
@import '../styles/constant.styl'

#home
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  box-sizing: border-box
  width: 7.5rem
  min-height: 'calc(100vh - %s)' % nav_bar_height
  padding: 0.16rem 0.08rem
  background-color: bg_color_article
  text-align: left
</style>
