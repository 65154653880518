export const msg={
  ok: '确定',
  submit: '提交',
  cancel: '取消',
  save: '保存',
  clickToShowMore: '点击查看更多',

  searchPlaceholder: '请输入关键词搜索',

  user: '用户',
  login: '登录',
  logout: '退出',
  register: '注册',
  loginOrRegister: '登录/注册',
  userName: '用户名',
  firstName: '姓',
  lastName: '名',
  password: '密码',
  originalPassword: '原密码',
  changePwd: '修改密码',
  forgetPwd: '忘记密码',
  confirmPassword: '确认密码',
  email: 'E-mail',
  captcha: '验证码',
  emailCheckCode: '邮箱校验码',
  sendEmailCheckCode: '发送校验码',

  unautherizedAccess: '未授权访问！',
  authTimeout: '授权超时！',

  userNameEmpty: '用户名不能为空！',
  pwdEmpty: '密码不能为空！',
  differentPwd: '两次输入密码不相同',
  invalidEmail: '非法邮箱地址',
  invalidCaptcha: '验证码必须为4位',
  userNameMetric: '用户名必须为4-24个字符，不能包含敏感词、控制字符、空白字符和符号，且不能以数字开头。',
  userPwdMetric: '密码必须至少6个字符，且需要包含大小写字母、数字、特殊符号中至少两类。',
  invalidCheckCode: '校验码必须为8位',

  sendActivateEmail: '发送激活邮件',
  resend: '重新发送({0})',
  activateWaiting: '激活中...',
  activateSuccess: '用户激活成功',
  activateFail: '用户激活失败',

  fieldRequiring: '所有必要字段都需要填写',

  latestRelease: '最新版本',
  publishedOn: '发布于',

  version: '版本',
  time: '时间',
  componentAssets: '组件资产',

  sponsor: '赞助',

  CaptchaStatus: {
    SUCCESS: '验证成功',
    FAILED: '验证码错误',
    TOO_FAST: '验证过快',
    EXPIRED: '验证码过期'
  },

  CheckCodeStatus: {
    SUCCESS: '校验成功',
    FAILED: '校验码错误',
    TOO_FAST: '校验过快',
    EXPIRED: '校验码过期'
  },

  RegisterCode: {
    SUCCESS: '注册成功',
    ILLEGAL_NAME: '非法用户名！',
    ILLEGAL_EMAIL: '非法邮箱',
    DUPLICATED_NAME: '用户名已被使用',
    FAILED: '注册失败'
  },

  LoginCode: {
    SUCCESS: '登录成功',
    FAILED: '登录失败，用户名或密码错误',
    DELETED_USER: '登录失败，用户已被永久删除'
  },

  LogoutCode: {
    SUCCESS: '退出成功',
    FAILED: '退出失败',
  },

  UserForgetPwdCode: {
    SUCCESS: '8位校验码已经发送到您的邮箱（来自no-reply@concopilot.org）',
    ILLEGAL_ACCESS: '非法访问',
    NO_SUCH_USER: '用户不存在',
    EMAIL_COOL_DOWN: '请稍后重试',
    EMAIL_SENDING_FAILED: '密码重置校验码邮件发送失败'
  },

  ChangePwdCode: {
    SUCCESS: '您的密码修改成功',
    ILLEGAL_ACCESS: '非法访问',
    INCORRECT_ORIGINAL_PWD: '原密码错误',
    NO_SUCH_USER: '用户不存在'
  },

  Role: {
    ADMIN: '管理员',
    REGISTERED: '注册用户',
    ANONYMOUS: '匿名用户'
  },
  UserStatus: {
    NORMAL: '正常',
    NONACTIVATED: '未激活',
    DELETED: '已删除'
  },

  UserProfile: {
    firstName: '姓：',
    lastName: '名：',
    email: 'E-mail：',
    createTime: '注册时间：',
    lastLoginTime: '最后登录时间：'
  },

  UserEditCode: {
    SUCCESS: '保存成功',
    NOT_LOGGED_IN: '用户未登录',
    ILLEGAL_USER_STATUS: '您当前的用户状态不允许修改用户信息',
    ILLEGAL_ACCESS: '非法访问',
    ILLEGAL_NAME: '非法用户名',
    ILLEGAL_EMAIL: '邮件地址不合法',
    NOT_ALLOWED_EXPLICIT: '您不被允许修改“显示敏感内容”'
  },

  UserActivateCode: {
    SUCCESS_EMAIL: '激活邮件已发往您的注册邮箱（来自no-reply@concopilot.org），请根据邮件内容操作激活帐号',
    NOT_LOGGED_IN: '用户未登录',
    ALREADY_ACTIVATED: '您的帐号已激活',
    ILLEGAL_USER_STATUS: '您不是未激活用户',
    EMAIL_COOL_DOWN: '请稍候重试',
    EMAIL_SENDING_FAILED: '激活邮件发送失败'
  },

  createOrganization: '创建组织',
  allOrganizationCreations: '所有提交 >>',
  showMore: '更多',
  showOpenOnly: '仅显示开放的工单',
  reviewTickets: '审阅工单',
  ticketStatusOpen: '开放中',
  ticketStatusClosed: '已关闭',
  ticketStatusPassed: '已通过',
  ticketStatusRejected: '未通过',
  replyTicketPrompt: '回复此工单',
  unavailableContent: '** 该内容无法显示 **',

  TicketOpt: {
    review: '审查',
    accept: '接受',
    reject: '拒绝'
  },

  Ticket: {
    OrgType: {
      COMPANY: '公司',
      COMMUNITY: '社区'
    },

    orgName: '组织名称',
    orgDes: '组织描述',
    orgType: '组织类型',
    domain: '域名',
    groupId: 'GroupId'
  },

  TicketCode: {
    SUCCESS: '请求成功',
    UNAUTHORIZED_READ: '您没有权限阅读此工单',
    UNAUTHORIZED_WRITE: '您没有权限提交工单',
    UNAUTHORIZED_GROUP_ID_QUERY: '您没有权限查询GroupId',
    UNAUTHORIZED_REVIEW: '您没有权限审核此工单',
    UNAUTHORIZED_CLOSE: '您没有权限关闭此工单',
    ILLEGAL_ORG_NAME: '非法组织名',
    ILLEGAL_ORG_DES: '非法组织描述',
    ILLEGAL_DOMAIN: '非法域名',
    ILLEGAL_DOMAIN_FOR_GROUP_ID: '您输入查询GroupId的域名不合法',
    FAILED_TO_INSERT_DB: '请求失败',
    FAILED: '请求失败'
  },

  ReviewResult: {
    owned: '归属确认',
    ticketId: '工单Id',
    checkedUrl: '审核URL',
    domain: '申请域名',
    groupId: '申请GroupId'
  },

  RepositoryCode: {
    SUCCESS: '操作成功',
    UNAUTHORIZED_GRANT: '您没有权限执行此操作',
    UNAUTHORIZED_ACCESS: '您没有访问此资源的权限',
    UNAUTHORIZED_UPLOAD: '您没有上传资产的权限',
    UPLOAD_WITH_EMPTY_PATH: '上传路径不能为空',
    UPLOAD_WITH_ILLEGAL_ARTIFACT_ID: '非法ArtifactId',
    UPLOAD_WITH_ILLEGAL_VERSION: '非法版本',
    UPLOAD_WITH_ILLEGAL_CONFIG_FILE: '非法配置文件',
    FAILED: '操作失败'
  }
}
