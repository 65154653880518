import DOMPurify from 'dompurify'
import mathjax from '@/scripts/mathjax.js'

let marked=require('marked')
let markedMangle=require('marked-mangle')
let markedGfmHeadingId=require('marked-gfm-heading-id')

marked.setOptions({
  // breaks: true,
  gfm: true
})
marked.use(markedMangle.mangle())
marked.use(markedGfmHeadingId.gfmHeadingId({prefix: ''}))

let hljs=require('highlight.js')

function markToDom(text, dom) {
  if(text!==null && text!==undefined) {
    dom.innerHTML=DOMPurify.sanitize(marked.parse(text))
    dom.querySelectorAll('pre code').forEach(el => {
      hljs.highlightElement(el)
    })
    mathjax.convert([dom])
  }
}

export default {
  markToDom
}
