import { createStore } from 'vuex'
import langs from '../vuei18n/langs.js'

export default createStore({
  state: {
    currLang: null,
    currLangKey: null,

    logined: localStorage.getItem('logined')==='true',
    loginedUserId: localStorage.getItem('loginedUserId'),
    loginedSessionTimeout: Number(localStorage.getItem('loginedSessionTimeout')),
    loginedTime: Number(localStorage.getItem('loginedTime'))
  },
  getters: {
  },
  mutations: {
    setCurrLang(state, lang, langKey=null) {
      state.currLang=lang
      state.currLangKey=langKey ? langKey : langs.langMap.nameToKey[lang]
      localStorage.setItem('currLangKey', state.currLangKey)
    },
    setCurrLangKey(state, langKey) {
      state.currLang=langs.langMap.keyToName[langKey]
      state.currLangKey=langKey
      localStorage.setItem('currLangKey', state.currLangKey)
    },
    setLogined(state, loginInfo) {
      state.logined=loginInfo && !!loginInfo.id
      state.loginedUserId=loginInfo ? loginInfo.id : null
      state.loginedSessionTimeout=loginInfo ? loginInfo.timeout : null
      localStorage.setItem('logined', state.logined)
      localStorage.setItem('loginedUserId', state.loginedUserId)
      localStorage.setItem('loginedSessionTimeout', String(state.loginedSessionTimeout))

      state.loginedTime=new Date().getTime()/1000
      localStorage.setItem('loginedTime', String(state.loginedTime))
    },
    setLoginedTime(state) {
      state.loginedTime=new Date().getTime()/1000
      localStorage.setItem('loginedTime', String(state.loginedTime))
    }
  },
  actions: {
  },
  modules: {
  }
})
