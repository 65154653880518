const uuid=require('uuid')

const axios=require('axios')
axios.defaults.withCredentials=true

function getCParam(currLang, traceId=null) {
  var requestId=uuid.v4()
  if(!traceId)
    traceId=requestId
  return {
    rId: requestId,
    tId: traceId,
    lang: currLang
  }
}

function request(url, bParam, currLangKey, traceId=null, moreParams={}, replacer=hideNoneValue) {
  var cParam=getCParam(currLangKey, traceId)
  return requestPost(url, bParam, cParam, moreParams, replacer)
}

function requestPost(url, bParam, cParam, moreParams={}, replacer=hideNoneValue) {
  return sendPost(url, bParam, cParam, null, {
    'Content-Type': 'application/x-www-form-urlencoded'
  }, moreParams, replacer)
}

function upload(url, bParam, formData, currLangKey, traceId=null, moreParams={}, replacer=hideNoneValue) {
  var cParam=getCParam(currLangKey, traceId)
  return uploadPost(url, bParam, cParam, formData, moreParams, replacer)
}

function uploadPost(url, bParam, cParam, formData, moreParams={}, replacer=hideNoneValue) {
  return sendPost(url, bParam, cParam, formData, {
    'Content-Type': 'multipart/form-data'
  }, moreParams, replacer)
}

function sendPost(url, bParam, cParam, formData=null, headers=null, moreParams={}, replacer=hideNoneValue) {
  formData=formData ? formData : new FormData()
  formData.append('b', JSON.stringify(bParam, replacer))
  formData.append('c', JSON.stringify(cParam, replacer))
  var reqParams={
    method: 'post',
    url: url,
    data: formData,
    headers: headers
  }
  Object.assign(reqParams, moreParams)
  return axios(reqParams)
}

function sendSimplePost(url) {
  return axios({
    method: 'post',
    url: url
  })
}

function sendSimpleGet(url) {
  return axios({
    method: 'get',
    url: url
  })
}

function hideNoneValue(k, v) {
  if(v!==null && v!==undefined) {
    return v
  }
}

function hideEmptyValue(k, v) {
  if(v!==null && v!==undefined && v!=='') {
    return v
  }
}

export default {
  getCParam,
  request,
  requestPost,
  upload,
  uploadPost,
  sendPost,
  sendSimplePost,
  sendSimpleGet,
  hideNoneValue,
  hideEmptyValue
}
