<template>
  <div class="search-view">
    <div class="sv-result-list">
      <component-summary v-for="item in searchResults" :key="item.id" :item="item" />
      <button v-show="searchResults.length>0 && continuationToken" @click="onClickSearchMore">{{$t('msg.showMore')}}</button>
    </div>
  </div>
</template>

<script>
import serviceComponent from '@/scripts/serviceComponent.js'
import ComponentSummary from '@/components/ComponentSummary.vue'

export default {
  name: 'SearchView',
  components: {
    ComponentSummary
  },
  data: function() {
    return {
      query: null,
      groupId: null,
      artifactId: null,
      searchResults: [],
      continuationToken: null
    }
  },
  props: {

  },
  created() {
    this.$watch(
      () => this.$route.query,
      () => {
        this.init(this.$route)
      },
      {immediate: true}
    )
  },
  methods: {
    init(route) {
      this.preprocessQuery(route.query.q)
      this.groupId=route.query.g
      this.artifactId=route.query.a
      this.searchResults=[]
      this.continuationToken=null

      let routerDataKey=history.state.rd
      if(routerDataKey) {
        delete history.state.rd
        let searchInfo=this.$cache.get(routerDataKey)
        if(searchInfo) {
          this.searchResults=searchInfo.searchResults
          this.continuationToken=searchInfo.continuationToken
          if(searchInfo.refresh && this.continuationToken) {
            this.requestSearch()
          }
        }
      }
      else if(this.query || this.groupId || this.artifactId) {
        this.requestSearch()
      }
    },
    preprocessQuery(query) {
      this.query=query
    },
    onClickSearchMore() {
      if(this.continuationToken) {
        this.requestSearch()
      }
    },
    requestSearch() {
      serviceComponent.requestSearch(this.$store, this.query, this.groupId, this.artifactId, this.continuationToken, this.$store.state.currLangKey, null)
        .then(data => {
          for(let item of data.components) {
            this.searchResults.push(item)
          }
          this.continuationToken=data.continuationToken
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'
@import '../styles/constant.styl'

.search-view
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  box-sizing: border-box
  width: 7.5rem
  min-height: 'calc(100vh - %s)' % nav_bar_height
  padding: 0 0.04rem
  background-color: bg_color_light
  font-size: 16px
  text-align: left

  button
    margin-bottom: 0.04rem
    min-width: 60px
</style>