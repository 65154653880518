<template>
  <div class="component-summary" @click.prevent="onClick">
    <div class="cs-line cs-title">
      <div>{{item.artifactId}}</div>
    </div>
    <div class="cs-line cs-tags">
      <div :class="tag!=='plugin' ? 'c-tag' : 'c-tag-plugin'" v-for="tag in item.tags" :key="tag">
        {{tag}}
      </div>
    </div>
    <div class="cs-line cs-path">
      <div class="cs-link" @click.stop="onClickGroupId">{{item.groupId}}</div>
      <span>»</span>
      <div class="cs-link" @click.stop="onClickArtifactId">{{item.artifactId}}</div>
      <span v-if="item.version">»</span>
      <div v-if="item.version" class="cs-link" @click.stop="onClickVersion">{{item.version}}</div>
    </div>
    <div class="cs-line cs-des" v-if="item.description">
      <div>{{item.description}}</div>
    </div>
    <div class="cs-line cs-footer">
      <div class="cs-last" v-if="item.latestVersion">
        <div>{{$t('msg.latestRelease')}}</div>
        <div class="cs-link" @click.stop="onClickLatestVersion">{{item.latestVersion}}</div>
        <div>{{$t('msg.publishedOn')}}</div>
      </div>
      <div>{{item.createTime}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentSummary',
  props: {
    item: Object,

    emitClick: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if(this.item.version) {
        this.onClickVersion()
      }
      else {
        this.onClickArtifactId()
      }
    },
    onClickGroupId() {
      this.$router.push({
        path: '/search',
        query: {
          g: this.item.groupId
        }
      })
    },
    onClickArtifactId() {
      this.$router.push({
        path: `/component/${this.item.groupId}/${this.item.artifactId}`
      })
    },
    onClickVersion() {
      this.$router.push({
        path: `/component/${this.item.groupId}/${this.item.artifactId}/${this.item.version}`
      })
    },
    onClickLatestVersion() {
      this.$router.push({
        path: `/component/${this.item.groupId}/${this.item.artifactId}/${this.item.latestVersion}`
      })
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'

.component-summary
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  background-color: item_bg_color
  margin: 0.04rem
  padding: 0.04rem

  &:hover
    outline: item_bg_color solid 2px
    background-color: item_bg_color_hover

  .cs-line
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    margin: 0.04rem 0

  .cs-link
    color: link_color
    cursor: pointer

  .cs-title
    font-size: 16px
    font-weight: bold

  .cs-tags
    font-size: 12px

  .cs-path
    font-size: 14px

    span
      margin: 0 0.04rem

  .cs-des
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    font-size: 16px
    text-align: left
    max-height: 80px
    overflow: auto

  .cs-footer
    margin-top: 0.08rem
    font-size: 12px
    color: component_tag_foot_color

    .cs-last
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: center

      div
        margin-right: 0.04rem
</style>
