<template>
  <div class="icon-spinner8 waiting-full" />
</template>

<script>
export default {
  name: 'WaitingFull'
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'
@import '../styles/vendor.styl'

.waiting-full
  position: absolute
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: waiting_bg_color
  color: waiting_spin_color
  font-size: 32px

  &::before
    prefix(animation, spin 0.8s linear infinite)

    @keyframes spin
      0%
        transform: rotate(0)
      100%
        transform: rotate(360deg)
</style>
