<template>
  <div class="component">
    <div class="c-header">
      <div class="c-line c-title">
        <div>{{artifactId}}</div>
      </div>
      <div class="c-line c-tags">
        <div :class="tag!=='plugin' ? 'c-tag' : 'c-tag-plugin'" v-for="tag in tags" :key="tag">
          {{tag}}
        </div>
      </div>
      <div class="c-line c-path">
        <div class="c-link" @click="onClickGroupId">{{groupId}}</div>
        <span>»</span>
        <div :class="{'c-link': version}" @click="version && onClickArtifactId()">{{artifactId}}</div>
        <span v-if="version">»</span>
        <div v-if="version">{{version}}</div>
      </div>
      <div class="c-line c-des" v-if="description">
        <div>{{description}}</div>
      </div>
    </div>
    <table v-if="versions.length>0">
      <thead>
        <tr>
          <th>{{$t('msg.version')}}</th>
          <th class="c-table-time">{{$t('msg.time')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="version in versions" :key="version[0]">
          <td>
            <div class="c-link" @click="onClickVersion(version[0])">{{version[0]}}</div>
          </td>
          <td class="c-table-time">{{version[1]}}</td>
        </tr>
      </tbody>
    </table>
    <div class="c-detail" v-if="version">
      <div class="c-detail-file-area">
        <div class="c-detail-component-assets">{{$t('msg.componentAssets')}}</div>
        <div class="c-detail-files">
          <div :class="['icon-file-text2', 'c-detail-file', fileIdx===idx ? 'chosen' : '']" v-for="(file, idx) in files" :key="file[1]" @click="fileIdx=idx">
            <div>{{file.name}}</div>
            <a :href="'/repository/releases/'+file.uri">raw</a>
          </div>
        </div>
      </div>
      <div class="c-detail-content">
        <div :class="detailContentStyle" ref="detailContent" />
      </div>
    </div>
  </div>
</template>

<script>
import serviceComponent from '@/scripts/serviceComponent.js'
import markIt from '@/scripts/markIt.js'

let csvToMarkdown=require('csv-to-markdown-table')

let unavailableContent='unavailableContent'

export default {
  name: 'Component',
  components: {

  },
  data: function() {
    return {
      groupId: '',
      artifactId: '',
      version: '',

      tags: [],
      description: '',

      components: [],
      continuationToken: null,
      item: null,
      versions: [],

      files: [],
      fileIdx: -1,
      detailContentStyle: ''
    }
  },
  props: {

  },
  watch: {
    fileIdx(val) {
      if(val>=0) {
        let file=this.files[val]
        if(!file.content) {
          serviceComponent.requestAsset(this.$store, file.uri)
            .then(response => {
              file.contentType=response.headers.get('content-type').toLowerCase().trim()
              if(response.headers.get('content-length')<40960 && (file.contentType.startsWith('text/') || file.contentType==='application/json' || file.contentType==='application/xml')) {
                response.text()
                  .then(text => {
                    file.content=text
                    this.renderContent(file)
                  })
              }
              else {
                file.content=unavailableContent
                this.renderContent(file)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
        else {
          this.renderContent(file)
        }
      }
    }
  },
  created() {
    this.$watch(
      () => this.$route.path,
      () => {
        this.init(this.$route)
      },
      {immediate: true}
    )
  },
  methods: {
    init(route) {
      let items=route.path.split('/')
      this.groupId=items[2]
      this.artifactId=items[3]
      this.version=items.length>4 ? items[4] : null

      this.tags=[]
      this.description=''

      this.components=[]
      this.continuationToken=null
      this.versions=[]

      this.files=[]
      this.fileIdx=-1
      this.detailContentStyle=''

      this.requestComponent()
    },
    renderContent(file) {
      let dom=this.$refs.detailContent
      if(file.html) {
        dom.innerHTML=file.html
        this.detailContentStyle=file.style
      }
      else if(file.content===unavailableContent) {
        dom.innerHTML=this.$t('msg.'+file.content)
        this.detailContentStyle='c-detail-unavailable'
      }
      else if(file.contentType.startsWith('text/')) {
        if(file.contentType.includes('text/plain')) {
          markIt.markToDom(`\`\`\`plaintext\n${file.content.replaceAll('`', '\\`')}\n\`\`\``, dom)
          dom.innerHTML=dom.innerHTML.replaceAll('\\`', '`')
          this.detailContentStyle='c-detail-text hljs-body-light'
          file.html=dom.innerHTML
          file.style=this.detailContentStyle
        }
        else if(file.contentType.includes('markdown')) {
          markIt.markToDom(file.content, dom)
          this.detailContentStyle='c-detail-md markdown-body hljs-body-dark'
          file.html=dom.innerHTML
          file.style=this.detailContentStyle
        }
        else if(file.contentType.includes('csv')) {
          markIt.markToDom(csvToMarkdown(file.content, ',', true), dom)
          this.detailContentStyle='c-detail-md markdown-body hljs-body-dark'
          file.html=dom.innerHTML
          file.style=this.detailContentStyle
        }
        else {
          let codeLang=''
          if(file.contentType.includes('/x-c')) {
            codeLang=file.contentType.includes('/x-c++') ? 'cpp' : 'c'
          }
          else {
            for(codeLang of ['yaml', 'html', 'javascript', 'css', 'xml', 'python', 'java', 'php']) {
              if(file.contentType.includes(codeLang)) {
                break
              }
            }
          }
          markIt.markToDom(`\`\`\`${codeLang}\n${file.content}\n\`\`\``, dom)
          this.detailContentStyle='c-detail-code hljs-body-light'
          file.html=dom.innerHTML
          file.style=this.detailContentStyle
        }
      }
      else if(file.contentType.startsWith('application/')) {
        for(let codeLang of ['json', 'xml']) {
          if(file.contentType.includes(codeLang)) {
            markIt.markToDom(`\`\`\`${codeLang}\n${file.content}\n\`\`\``, dom)
            this.detailContentStyle='c-detail-code hljs-body-light'
            file.html=dom.innerHTML
            file.style=this.detailContentStyle
            return
          }
        }
      }
      // else if(file.contentType.includes('markdown')) {
      //   markIt.markToDom(file.content, dom)
      //   this.detailContentStyle='c-detail-md markdown-body hljs-body-dark'
      //   file.html=dom.innerHTML
      //   file.style=this.detailContentStyle
      // }
      // else if(file.contentType.includes('text/plain')) {
      //   markIt.markToDom(`\`\`\`plaintext\n${file.content.replaceAll('`', '\\`')}\n\`\`\``, dom)
      //   dom.innerHTML=dom.innerHTML.replaceAll('\\`', '`')
      //   this.detailContentStyle='c-detail-text hljs-body-light'
      //   file.html=dom.innerHTML
      //   file.style=this.detailContentStyle
      // }
      // else {
      //   for(let codeLang of ['yaml', 'json', 'html', 'javascript', 'css', 'xml']) {
      //     if(file.contentType.includes(codeLang)) {
      //       markIt.markToDom(`\`\`\`${codeLang}\n${file.content}\n\`\`\``, dom)
      //       this.detailContentStyle='c-detail-code hljs-body-light'
      //       file.html=dom.innerHTML
      //       file.style=this.detailContentStyle
      //       return
      //     }
      //   }
      //   markIt.markToDom(`\`\`\`\n${file.content}\n\`\`\``, dom)
      //   this.detailContentStyle='c-detail-code hljs-body-light'
      //   file.html=dom.innerHTML
      //   file.style=this.detailContentStyle
      // }
    },
    onClickGroupId() {
      this.$router.push({
        path: '/search',
        query: {
          g: this.groupId
        }
      })
    },
    onClickArtifactId() {
      this.$router.push({
        path: `/component/${this.groupId}/${this.artifactId}`
      })
    },
    onClickVersion(version) {
      this.$router.push({
        path: `/component/${this.groupId}/${this.artifactId}/${version}`
      })
    },
    requestComponent() {
      serviceComponent.requestSearch(this.$store, null, this.groupId, this.artifactId, this.continuationToken, this.$store.state.currLangKey, null)
        .then(data => {
          this.components=data.components
          this.continuationToken=data.continuationToken
          if(this.components.length>0) {
            this.tags=this.components[0].tags
            this.description=this.components[0].description
            if(!this.version) {
              for(let c of this.components) {
                if(c.version) {
                  this.versions.push([c.version, c.createTime.substring(0, 10)])
                }
              }
            }
            else {
              serviceComponent.requestComponent(this.$store, this.groupId, this.artifactId, this.version)
                .then(data => {
                  this.files=data
                  if(this.files.length>0) {
                    let idx=0
                    let readmeIdx=null
                    let configIdx=null
                    for(let file of data) {
                      let fileName=file.name.toLowerCase()
                      if(fileName==='config.yaml' || fileName==='config.yml') {
                        configIdx=idx
                      }
                      else if(fileName==='readme.md') {
                        readmeIdx=idx
                      }
                      ++idx
                    }
                    if(readmeIdx!=null) {
                      this.fileIdx=readmeIdx
                    }
                    else if(configIdx!=null) {
                      this.fileIdx=configIdx
                    }
                  }
                })
                .catch(error => {
                  console.log(error)
                })
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'
@import '../styles/constant.styl'

.component
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  box-sizing: border-box
  width: 7.5rem
  min-height: 'calc(100vh - %s)' % nav_bar_height
  padding: 0 0.04rem
  background-color: bg_color
  font-size: 16px
  text-align: left

  .c-line
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    margin: 0.04rem 0

  .c-link
    color: link_color
    cursor: pointer

  .c-header
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch

    .c-title
      margin-top: 0.16rem
      font-size: 24px
      font-weight: bold

    .c-tags
      font-size: 12px

    .c-path
      margin-top: 0.08rem
      font-size: 14px

      span
        margin: 0 0.04rem

    .c-des
      font-size: 16px

  table
    margin-top: 0.2rem
    text-align: left

    thead
      background-color: bg_color_dark

    tbody
      background-color: bg_color_article

    .c-table-time
      width: 100px

  .c-detail
    margin-top: 0.2rem

    .c-detail-file-area
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: stretch

      .c-detail-component-assets
        font-size: 16px
        font-weight: bold

      .c-detail-files
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        margin-top: 0.04rem
        background-color: bg_color_light
        padding: 0.02rem 0.04rem
        font-size: 14px

        .c-detail-file
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: flex-start
          background-color: item_bg_color
          border-radius: 0.02rem
          margin: 0.02rem 0
          padding: 0.06rem 0.04rem
          cursor: default

          &:hover
            background-color: item_bg_color_chosen

          &.chosen
            background-color: item_bg_color_chosen

          div
            margin-left: 0.04rem

          a
            text-decoration: none
            margin-left: 0.08rem

    .c-detail-content
      margin-top: 0.1rem

      .select-tabs
        font-size: 16px

      .c-detail-md
        padding: 0.32rem 0.16rem
        background-color: bg_color_article

      .c-detail-text

        pre
          margin: 0

          code
            background-color: bg_color_article

      .c-detail-code

        pre
          margin: 0

      .c-detail-unavailable
        padding: 0.32rem 0.16rem
        text-align: center
        color: hint_text_color
        overflow: auto
</style>