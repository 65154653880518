<template>
  <nav>
    <keep-alive>
      <navigation/>
    </keep-alive>
  </nav>
  <router-view/>
  <div class="common-back" />
</template>

<script>
import display from '@/scripts/display.js'

display.flexibleWidth(750, 750)

import Navigation from '@/components/Navigation.vue'

export default {
  components: {
    Navigation
  },
  beforeCreate: function() {
    this.$store.commit('setCurrLangKey', this.$i18n.locale)
  }
}
</script>

<style lang="stylus">
@import '~normalize.css'
@import '/common/stylus/icon.styl'
@import '/styles/color.styl'
@import '/styles/common.styl'
@import '/styles/github-markdown-light.styl'
@import '/styles/atom-one-dark.styl'
@import '/styles/atom-one-light.styl'

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: default_text_color

  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  z-index: 0

  .common-back
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100vh
    background-color: bg_color_common_back
    z-index: -1
</style>
