<template>
  <div class="activate-bg">
    <div class="activate">
      <div v-for="s in status" :key="s">{{$t(s)}}</div>
      <button @click="onClick">{{$t('msg.ok')}}</button>
    </div>
  </div>
</template>

<script>
import vueRouter from '@/scripts/vueRouter.js'
import requester from '@/scripts/requester.js'

export default {
  name: 'Activate',
  data: function() {
    return {
      status: ['msg.activateWaiting']
    }
  },
  props: {
    type: String,
    userId: String,
    code: String,
  },
  created() {
    requester.request('/api/activate', {
      activateType: this.type,
      id: this.userId,
      code: this.code
    }, this.$store.state.currLangKey)
      .then(response => {
        this.status=['msg.'+response.data.status.des, (response.data.status.code===0 ? 'msg.activateSuccess' : 'msg.activateFail')]
      })
      .catch(error => {
        console.log(this.$t('msg.'+error))
      })
  },
  methods: {
    onClick() {
      vueRouter.backOrDefault('/')
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'

.activate-bg
  position: fixed
  top: 0
  left: 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100vw
  height: 100vh
  background-color: bg_color_mask
  z-index: 200

  .activate
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    box-sizing: border-box
    width: 6.4rem
    min-height: 16vh
    background-color: bg_color

    button
      margin-top: 0.2rem
      border: none
      border-radius: 4px
      outline: none
      min-width: 64px
      background-color: button_color
</style>
