<template>
  <div class="user-profile" v-if="user" @click.stop="onBlurEditable">
    <div class="user-profile-header">
      <div class="user-profile-header-info">
        <div class="user-profile-header-head user-profile-row-info">
          <div class="user-profile-name">{{user.name}}</div>
          <div class="user-profile-role">{{$t('msg.Role.'+user.role)}}</div>
          <div class="user-profile-status">{{$t('msg.UserStatus.'+user.status)}}</div>
        </div>
        <div class="user-profile-row-info">
          <div class="user-profile-sub-row-info" @click.stop="onFocusEditable($refs.firstName)">
            <div>{{$t('msg.UserProfile.firstName')}}</div>
            <input :class="['user-profile-header-input', editing ? 'editing' : 'editable']" :contenteditable="editing" ref="firstName" v-model="user.firstName" @focus="onFocusEditable($refs.firstName)" @input="onInputFirstName" />
          </div>
          <div class="user-profile-sub-row-info" @click.stop="onFocusEditable($refs.lastName)">
            <div>{{$t('msg.UserProfile.lastName')}}</div>
            <input :class="['user-profile-header-input', editing ? 'editing' : 'editable']" :contenteditable="editing" ref="lastName" v-model="user.lastName" @focus="onFocusEditable($refs.lastName)" @input="onInputLastName" />
          </div>
        </div>
        <div class="user-profile-row-info" @click.stop="onFocusEditable($refs.email)">
          <div>{{$t('msg.UserProfile.email')}}</div>
          <input :class="['user-profile-header-input', editing ? 'editing' : 'editable', validEmail ? '' : 'illegal']" :contenteditable="editing" ref="email" v-model="user.email" @focus="onFocusEditable($refs.email)" @input="onInputEmail" />
        </div>
        <div class="button-area">
          <button v-show="self" @click="onChangePwd">{{$t('msg.changePwd')}}</button>
          <button v-if="self && user.status==='NONACTIVATED'" @click="onSendActivateEmail">{{$t(activateEmailText, [activateEmailCountdown])}}</button>
          <button v-show="changed" @click="onSave">{{$t('msg.save')}}</button>
          <button v-if="self && user.role==='ADMIN'" @click="onReviewTickets">{{$t('msg.reviewTickets')}}</button>
        </div>
      </div>
    </div>
    <div class="user-profile-login-info">
      <div class="user-profile-time user-profile-row-info">
        <span>{{$t('msg.UserProfile.createTime')}}</span>
        <div>{{user.createTime}}</div>
      </div>
      <div class="user-profile-time user-profile-row-info">
        <span>{{$t('msg.UserProfile.lastLoginTime')}}</span>
        <div>{{user.lastLoginTime}}</div>
      </div>
    </div>
    <div class="user-profile-org" v-if="self">
      <hr>
      <div class="user-profile-org-row-info" style="margin-top: 0">
        <div class="user-profile-org-title">{{$t('msg.createOrganization')}}</div>
        <a class="user-profile-org-all-submission" :href="'/ticket/'+user.id+(user.role==='ADMIN' ? '?admin=true' : '')">{{$t('msg.allOrganizationCreations')}}</a>
      </div>
      <div class="user-profile-org-row-info">
        <input class="user-profile-org-input grow" :placeholder="$t('msg.Ticket.orgName')" v-model="org.orgName" />
      </div>
      <div class="user-profile-org-row-info">
        <input class="user-profile-org-input grow" :placeholder="$t('msg.Ticket.orgDes')" v-model="org.orgDes" />
      </div>
      <div class="user-profile-org-row-info">
        <select class="user-profile-org-input grow" v-model="org.orgType">
          <option value="" disabled selected hidden>{{$t('msg.Ticket.orgType')}}</option>
          <option value="COMPANY">{{$t('msg.Ticket.OrgType.COMPANY')}}</option>
          <option value="COMMUNITY">{{$t('msg.Ticket.OrgType.COMMUNITY')}}</option>
        </select>
      </div>
      <div class="user-profile-org-row-info">
        <input class="user-profile-org-input grow" :placeholder="$t('msg.Ticket.domain')" v-model="org.domain" @input="onOrgDomainInput" />
      </div>
      <div class="user-profile-org-row-info">
        <input :class="['user-profile-org-input', 'grow', validGroupId ? '' : 'illegal']" :placeholder="$t('msg.Ticket.groupId')" v-model="org.groupId" readonly />
      </div>
      <div class="user-profile-org-row-info" style="justify-content: flex-end">
        <button @click="onCreateOrganization">{{$t('msg.submit')}}</button>
      </div>
    </div>
    <waiting-full v-if="requesting" />
  </div>
</template>

<script>
import requesterCheckUser from '@/scripts/requesterCheckUser.js'
import dates from '@/scripts/dates.js'
import normStr from '@/scripts/normStr.js'
import serviceUser from '@/scripts/serviceUser.js'
import serviceContent from '@/scripts/serviceContent.js'
import WaitingFull from '@/components/WaitingFull.vue'

const axios=require('axios')
axios.defaults.withCredentials=true

export default {
  name: 'User',
  components: {
    WaitingFull
  },
  data: function() {
    return {
      user: null,
      self: false,

      editing: false,
      changed: false,

      activateEmailCountdown: 0,
      activateEmailText: null,

      validEmail: true,

      org: {
        orgName: '',
        orgDes: '',
        orgType: '',
        domain: '',
        groupId: ''
      },
      validGroupId: false,

      timer: null,
      cancelSource: null,
      requesting: false,

      traceId: null
    }
  },
  watch: {
    activateEmailCountdown: {
      handler(newCountdown) {
        if(newCountdown<=0) {
          this.activateEmailText='msg.sendActivateEmail'
        }
        else {
          setTimeout(() => {
            this.activateEmailCountdown=newCountdown-1
          }, 1000)
          this.activateEmailText='msg.resend'
        }
      },
      immediate: true
    }
  },
  created() {
    this.$watch(
      () => this.$route.query.id,
      (newId) => {
        this.readUser(newId)
      },
      {immediate: true}
    )
  },
  methods: {
    onFocusEditable(target) {
      if(this.self && (this.user.status==='NORMAL' || this.user.status==='NONACTIVATED')) {
        this.editing=true
        target && this.$nextTick(() => {
          target.focus()
        })
      }
    },
    onBlurEditable() {
      this.editing=false
    },
    onInput() {
      this.changed=true
    },
    onInputFirstName() {
      this.onInput()
    },
    onInputLastName() {
      this.onInput()
    },
    onInputEmail() {
      this.onInput()
      this.validEmail=normStr.validEmail(this.user.email)
    },
    onChangePwd() {
      this.$router.push({
        name: 'ResetPwd',
        state: {
          forget: false
        }
      })
    },
    onSendActivateEmail() {
      if(this.activateEmailCountdown<=0) {
        if(!this.requesting) {
          this.requesting=true
          serviceUser.sendActivateEmail(this.$store, this.$store.state.currLangKey)
            .then(response => {
              this.activateEmailCountdown=response.data.data!==null ? response.data.data : 0
              this.requesting=false
              alert(this.$t('msg.'+response.data.status.des))
            })
            .catch(error => {
              console.log(error)
              this.requesting=false
            })
        }
      }
    },
    onSave() {
      if(this.changed) {
        if(!this.validEmail) {
          alert(this.$t('msg.UserEditCode.ILLEGAL_EMAIL'))
          return
        }

        if(!this.requesting) {
          this.requesting=true
          let params={
            id: this.user.id,

            firstName: this.user.firstName,
            lastName: this.user.lastName,
            email: this.user.email,
          }
          requesterCheckUser.request(this.$store, '/api/userwrite', {user: params}, this.$store.state.currLangKey, this.traceId)
            .then(response => {
              if(response.data.status.code===0) {
                this.changed=false
                this.readUser(this.user.id)
              }
              this.requesting=false
              alert(this.$t('msg.'+response.data.status.des))
            })
            .catch(error => {
              console.log(error)
              this.requesting=false
            })
        }
      }
    },
    onOrgDomainInput() {
      this.trySendDomain()
    },
    onCreateOrganization() {
      if(!this.validGroupId) {
        alert(this.$t('msg.TicketCode.ILLEGAL_DOMAIN_FOR_GROUP_ID'))
      }
      else if(this.org.orgName && this.org.orgDes && this.org.orgType && this.org.domain) {
        if(!this.requesting) {
          this.requesting=true
          let content={
            organizationName: this.org.orgName,
            organizationDescription: this.org.orgDes,
            organizationType: this.org.orgType,
            domain: this.org.domain
          }
          serviceContent.contentWrite(this.$store, null, content, this.$store.state.currLangKey, this.traceId)
            .then(response => {
              if(response.data.status.code===0) {
                this.org.orgName=''
                this.org.orgDes=''
                this.org.orgType=''
                this.org.domain=''
                this.org.groupId=''
              }
              alert(this.$t('msg.'+response.data.status.des))
              this.requesting=false
            })
            .catch(error => {
              console.log(error)
              alert(this.$t('msg.TicketCode.FAILED'))
              this.requesting=false
            })
        }
      }
      else {
        alert(this.$t('msg.fieldRequiring'))
      }
    },
    onReviewTickets() {
      this.$router.push({
        name: 'TicketReview',
        query: {
          admin: true,
          a: true
        }
      })
    },

    readUser(userId) {
      this.cancelSource && this.cancelSource.cancel()
      this.cancelSource=axios.CancelToken.source()
      requesterCheckUser.request(this.$store, '/api/userread', {id: userId}, this.$store.state.currLangKey, this.traceId, {cancelToken: this.cancelSource.token})
        .then(response => {
          if(response.data.status.code===0 && response.data.data) {
            this.user=response.data.data
            if(this.user.createTime) {
              this.user.createTime=dates.toLocalDateStr(this.user.createTime)
            }
            if(this.user.lastLoginTime) {
              this.user.lastLoginTime=dates.toLocalDateStr(this.user.lastLoginTime)
            }
            this.self=this.$store.state.loginedUserId && this.$store.state.loginedUserId===this.user.id
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    trySendDomain() {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      if(this.org.domain!=null) {
        this.timer=setTimeout(() => {
          return this.requestGroupId(this.org.domain, this.$store.state.currLangKey)
            .then(response => {
              if(response.data.status.code===0) {
                this.org.groupId=response.data.data
                this.validGroupId=true
              }
              else if(response.data.status.des==='TicketCode.ILLEGAL_DOMAIN_FOR_GROUP_ID') {
                this.org.groupId=this.$t('msg.'+response.data.status.des)
                this.validGroupId=false
              }
            })
        }, 1000)
      }
    },
    requestGroupId(domain, currLangKey) {
      this.cancelSource && this.cancelSource.cancel()
      this.cancelSource=axios.CancelToken.source()
      return requesterCheckUser.request(this.$store, '/api/ticket/group-id', {d: domain}, currLangKey, null, {cancelToken: this.cancelSource.token})
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'
@import '../styles/constant.styl'

.user-profile
  position: relative
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  box-sizing: border-box
  width: 7.5rem
  min-height: 'calc(100vh - %s)' % nav_bar_height
  padding: 0 4px
  background-color: bg_color
  font-size: 16px
  text-align: left

  hr
    margin: 0.32rem 0

  .user-profile-row-info
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-end
    margin: 0.02rem 0

  .user-profile-sub-row-info
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    margin-right: 0.1rem

    div
      flex-shrink: 0

    input
      flex-shrink: 1
      width: 2rem
      min-width: 1rem

  .editable
    flex-grow: 1
    min-height: 18px
    background-color: transparent

  .editing
    flex-grow: 1
    min-height: 18px
    background-color: bg_color_editor

  .user-profile-header
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start
    margin-top: 0.08rem

    .user-profile-header-info
      position: relative
      flex-grow: 1
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: stretch

      .user-profile-header-head
        margin-bottom: 0.12rem

        .user-profile-name
          font-size: 32px
          margin-right: 0.04rem

      .user-profile-role
        border-radius: 4px
        margin: 0px 0.02rem
        padding: 1px 4px
        font-size: 12px
        background-color: #feffca
        color: #3e3640

      .user-profile-status
        border-radius: 4px
        margin: 0px 0.02rem
        padding: 1px 4px
        font-size: 12px
        background-color: #fbffde
        color: #7d8386

      .user-profile-header-input
        border: none
        padding: 0

        &.illegal
          color: red

      .button-area
        position: absolute
        top: 100%
        right: 0
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-end

        button
          margin-bottom: 0.04rem
          border: none
          border-radius: 4px
          outline: none
          min-width: 64px
          background-color: button_color
          font-size: 14px
          text-overflow: ellipsis
          white-space: nowrap
          word-break: keep-all
          overflow: hidden

  .user-profile-login-info
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch

    .user-profile-time
      font-size: 12px

  .user-profile-org
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    font-size: 16px
  
    .grow
      flex-grow: 1

    .user-profile-org-title
      font-size: 20px

    .user-profile-org-all-submission
      margin-left: 0.4rem
      font-size: 12px

    .user-profile-org-row-info
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-end
      margin: 0.08rem 0

      input:read-only
        background-color: bg_color_editor_read_only

      .user-profile-org-input
        border: none
        background-color: bg_color_editor

        &.illegal
          color: red

      button
        margin-bottom: 0.04rem
        border: none
        border-radius: 4px
        outline: none
        background-color: button_color
        text-overflow: ellipsis
        white-space: nowrap
        word-break: keep-all
        overflow: hidden
</style>

