export default (app, config={
  size: 10000,
  timeout: 60000
}) => {
  let cache={
    __data: {},
    put(data) {
      let key=String(Math.floor(Math.random()*config.size+1))
      this.__data[key]={
        data,
        ts: Date.now()
      }
      return key
    },
    get(key) {
      key=String(key)
      let data=null
      if(key in this.__data) {
        data=this.__data[key].data
        delete this.__data[key]
      }
      return data
    }
  }

  setInterval(() => {
    let due=Date.now()-config.timeout
    for(let [key, value] of Object.entries(cache.__data)) {
      if(value.ts<due) {
        delete cache.__data[key]
      }
    }
  }, config.timeout)

  app.config.globalProperties.$cache=cache
}