<template>
  <div class="ticket">
    <div class="ticket-checkbox-area">
      <div>
        <input type="checkbox" id="open-only" v-model="showOpenOnly" :disabled="noAuth">
        <label for="open-only">{{$t('msg.showOpenOnly')}}</label>
      </div>
    </div>
    <hr>
    <div class="ticket-list">
      <ticket-content class="ticket-item" v-for="c in contentList" :key="c.id" :content="c" :is-admin="isAdmin" @updateStatus="onUpdateStatus($event, c)" />
      <div class="ticket-list-show-more" v-if="hasMore">
        <button @click="requestContent">{{$t('msg.showMore')}}</button>
      </div>
    </div>
    <waiting-full v-if="requesting" />
  </div>
</template>

<script>
import serviceContent from '@/scripts/serviceContent.js'
import TicketContent from '@/components/TicketContent.vue'
import WaitingFull from '@/components/WaitingFull.vue'

export default {
  name: 'Ticket',
  components: {
    TicketContent,
    WaitingFull
  },
  data: function() {
    return {
      showOpenOnly: true,
      ticketList: [],
      page: 0,
      hasMore: true,
      noAuth: false,

      requesting: false
    }
  },
  props: {
    userId: {
      type: String,
      default: null
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    showOpenOnly() {
      this.init(this.showOpenOnly)
    }
  },
  created() {
    this.$watch(
      () => this.$route.query,
      () => {
        this.init(this.showOpenOnly)
      },
      {immediate: true}
    )
  },
  methods: {
    init(showOpenOnly) {
      this.showOpenOnly=showOpenOnly,
      this.contentList=[],
      this.page=0,
      this.hasMore=true,

      this.requesting=false

      this.requestContent()
    },
    requestContent() {
      if(!this.requesting) {
        this.requesting=true
        return serviceContent.contentRead(this.$store, null, this.userId, this.showAll, this.showOpenOnly, this.page, this.$store.state.currLangKey, this.traceId)
          .then(data => {
            if(data.length>0) {
              for(let content of data) {
                this.contentList.push(content)
              }
              this.page+=1
            }
            else {
              this.hasMore=false
            }
            this.noAuth=false
            this.requesting=false
          })
          .catch(error => {
            alert(this.$t('msg.'+error))
            this.hasMore=false
            this.noAuth=true
            this.requesting=false
          })
      }
    },
    onUpdateStatus($event, content) {
      content.isOpen=$event
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'
@import '../styles/constant.styl'

.ticket
  position: relative
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: stretch
  box-sizing: border-box
  width: 7.5rem
  min-height: 'calc(100vh - %s)' % nav_bar_height
  padding: 0 0.04rem
  background-color: bg_color_dark
  font-size: 16px
  text-align: left

  .ticket-checkbox-area
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    height: 32px
    padding: 0.04rem
    background-color: bg_color

    label
      margin-left: 2px

  .ticket-list
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch

    .ticket-item
      position: relative
      margin: 0.04rem 0 0

    .ticket-list-show-more
      display: flex
      flex-direction: row
      justify-content: center
      align-items: flex-end
      margin: 0.04rem 0
</style>