import requester from '@/scripts/requester.js'
import serviceUser from '@/scripts/serviceUser.js'

function request(store, url, bParam, currLangKey, traceId=null, moreParams={}, replacer=requester.hideNoneValue) {
  if(store.state.logined && new Date().getTime()/1000-store.state.loginedTime>=store.state.loginedSessionTimeout-30) {
    return serviceUser.checkLogin()
      .then(res => {
        store.commit('setLogined', res.data.data)
        return requester.request(url, bParam, currLangKey, traceId, moreParams, replacer)
      })
      .catch(() => {
        store.commit('setLogined', null)
        return requester.request(url, bParam, currLangKey, traceId, moreParams, replacer)
      })
  }
  else {
    return requester.request(url, bParam, currLangKey, traceId, moreParams, replacer)
  }
}

function upload(store, url, bParam, formData, currLangKey, traceId=null, moreParams={}, replacer=requester.hideNoneValue) {
  if(store.state.logined && new Date().getTime()/1000-store.state.loginedTime>=store.state.loginedSessionTimeout-30) {
    return serviceUser.checkLogin()
      .then(res => {
        store.commit('setLogined', res.data.data)
        return requester.upload(url, bParam, formData, currLangKey, traceId, moreParams, replacer)
      })
      .catch(() => {
        return requester.upload(url, bParam, formData, currLangKey, traceId, moreParams, replacer)
      })
  }
  else {
    return requester.upload(url, bParam, formData, currLangKey, traceId, moreParams, replacer)
  }
}

function sendSimplePost(store, url) {
  if(store.state.logined && new Date().getTime()/1000-store.state.loginedTime>=store.state.loginedSessionTimeout-30) {
    return serviceUser.checkLogin()
      .then(res => {
        store.commit('setLogined', res.data.data)
        return requester.sendSimplePost(url)
      })
      .catch(() => {
        return requester.sendSimplePost(url)
      })
  }
  else {
    return requester.sendSimplePost(url)
  }
}

function sendFetch(store, url) {
  if(store.state.logined && new Date().getTime()/1000-store.state.loginedTime>=store.state.loginedSessionTimeout-30) {
    return serviceUser.checkLogin()
      .then(res => {
        store.commit('setLogined', res.data.data)
        return requester.sendSimplePost(url)
      })
      .catch(() => {
        return requester.sendSimplePost(url)
      })
  }
  else {
    return fetch(url)
  }
}

export default {
  request,
  upload,
  sendSimplePost,
  sendFetch
}
