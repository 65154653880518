<template>
  <div class="review-result-bg" v-if="modelValue">
    <div class="review-result">
      <div class="review-result-line">
        <div class="review-result-line-title">{{$t('msg.ReviewResult.owned')}}</div>
        <div :class="['review-result-line-value', result.owned ? 'result-true' : 'result-false']">{{result.owned}}</div>
      </div>
      <div class="review-result-line">
        <div class="review-result-line-title">{{$t('msg.ReviewResult.ticketId')}}</div>
        <div class="review-result-line-value">{{result.ticketId}}</div>
      </div>
      <div class="review-result-line">
        <div class="review-result-line-title">{{$t('msg.ReviewResult.checkedUrl')}}</div>
        <div class="review-result-line-value">{{result.checkedUrl}}</div>
      </div>
      <div class="review-result-line">
        <div class="review-result-line-title">{{$t('msg.ReviewResult.domain')}}</div>
        <div class="review-result-line-value">{{result.domain}}</div>
      </div>
      <div class="review-result-line">
        <div class="review-result-line-title">{{$t('msg.ReviewResult.groupId')}}</div>
        <div class="review-result-line-value">{{result.groupId}}</div>
      </div>
      <span class="icon-cross review-result-cross" @click="onClickClose()"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewResult',
  components: {
  },
  data: function() {
    return {
      
    }
  },
  props: {
    result: Object,

    modelValue: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onClickClose() {
      this.$emit('update:modelValue', false)
    }
  }
}
</script>

<style scope lang="stylus">
@import '../styles/color.styl'

.review-result-bg
  position: absolute
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: bg_color_mask

  .review-result
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    width: 4.8rem
    padding: 0.2rem 0.25rem
    background-color: bg_color

    .review-result-line
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: center
      font-size: 16px

      .result-false
        color: crimson
        font-weight: bold

      .result-true
        color: green
        font-weight: bold

      .review-result-line-title
        font-weight: bold

      .review-result-line-value
        margin-left: 0.08rem
        font-style: italic

    .review-result-cross
      position: absolute
      top: 0.08rem
      right: 0.08rem
      font-size: 12px
      color: icon_cross_color
</style>