import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './vuei18n'

import cache from './plugins/cache'

createApp(App).use(store).use(router).use(i18n).use(cache).mount('#app')
